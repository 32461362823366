import { styled } from '@mui/material'
import { DateTime } from 'luxon'
import { TimePicker as MuiTimePicker, TimePickerProps } from '@mui/x-date-pickers'

export const TimePicker = styled((properties: TimePickerProps<DateTime>) => (
    <MuiTimePicker
        slotProps={{
            textField: {
                variant: 'filled',
            },
            popper: {
                sx: {
                    '& .MuiMenuItem-root': {
                        '&.Mui-selected': {
                            color: 'white !important',
                        },
                    },
                },
            },
        }}
        {...properties}
    />
))({
    '& .MuiFilledInput-root': {
        '&:before, &:after': {
            borderBottom: 'none',
        },
        '&:hover:before': {
            borderBottom: 'none !important',
        },
        '&.Mui-focused:before, &.Mui-focused:after': {
            borderBottom: 'none',
        },
    },
}) as typeof MuiTimePicker
