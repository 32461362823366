import { Group } from '../../groups/Group'
import { MinimalUserInfo } from '../../user/User'

export interface GroupWithAccessRequestsViewModel {}

export class GroupWithAccessRequestsHeaderViewModel implements GroupWithAccessRequestsViewModel {
    constructor(public readonly group: Pick<Group, 'id' | 'name'>) {}
}

export class GroupWithAccessRequestsHeaderLoadingViewModel
    implements GroupWithAccessRequestsViewModel {}

export class GroupWithAccessRequestsItemViewModel implements GroupWithAccessRequestsViewModel {
    constructor(
        public readonly member: MinimalUserInfo,
        public readonly group: Pick<Group, 'id' | 'name'>
    ) {}
}

export class GroupWithAccessRequestsItemLoadingViewModel
    implements GroupWithAccessRequestsViewModel {}
