import { Box, styled, Typography } from '@mui/material'
import AutoSizer from 'react-virtualized-auto-sizer'
import { paperColor } from 'shared/lib/theme/Theme'
import { Group } from '../groups/Group'
import { GroupWithAccessRequests } from '../groups/GroupWithAccessRequests'
import { useTranslation } from 'shared/lib/i18n'
import { MinimalUserInfo } from '../user/User'
import {
    GroupsWithAccessRequestsList,
    SEE_MORE_BUTTON_HEIGHT,
} from './groupsWithAccessRequestsList/GroupsWithAccessRequestsList'

interface Properties {
    groupAccessRequests: GroupWithAccessRequests[]

    onApproveGroupMember(groupId: string, userId: string): void
    onDenyGroupMember(groupMemberToDeny: {
        groupMember: MinimalUserInfo
        groupId: Group['id']
    }): void
}

export const GroupAccessRequestsCard = ({
    groupAccessRequests,
    onApproveGroupMember,
    onDenyGroupMember,
}: Properties) => {
    const translations = useTranslation()

    return groupAccessRequests.length ? (
        <Box height="100%">
            <AutoSizer disableWidth={true}>
                {({ height }) => (
                    <Card>
                        <Typography variant="h3">
                            {translations('access_requests_groups')}
                        </Typography>
                        <GroupsWithAccessRequestsList
                            availableHeight={height - SEE_MORE_BUTTON_HEIGHT}
                            groupAccessRequests={groupAccessRequests}
                            isLoading={false}
                            onApproveGroupMember={onApproveGroupMember}
                            onDenyGroupMember={onDenyGroupMember}
                        />
                    </Card>
                )}
            </AutoSizer>
        </Box>
    ) : null
}

const Card = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    borderTopLeftRadius: 24,
    borderBottomLeftRadius: 24,
    backgroundColor: paperColor,
    padding: theme.spacing(3, 1, 2, 3),

    [theme.breakpoints.up('desktop')]: {
        borderRadius: 24,
    },
}))
