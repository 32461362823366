import { MinimalUserInfo } from '../../user/User'
import React from 'react'
import { Box } from '@mui/material'
import { UserListItemRow } from '../../user/userList/UserListItemRows'
import { NotInGroupIcon } from 'shared/lib/assets/svg/NotInGroupIcon'
import { sortUsersByNameFn } from '../../user/Utils'
import { firstBy } from 'thenby'
import { StyledVirtuoso } from '../../common/StyledVirtuoso'
import { hideScrollbar } from 'shared/lib/theme/constants'

interface Properties {
    users: (MinimalUserInfo & { isGroupMember: boolean })[]
    width: number

    onUserClicked(userId: string): void
}

const maxHeight = 56 * 4 + 40

export const TagUserList = ({ users, width, onUserClicked }: Properties) => {
    const sortedUsers = users.sort(firstBy('isGroupMember', 'desc').thenBy(sortUsersByNameFn))

    return (
        <Box
            className="tag-user-list"
            sx={{
                borderRadius: '24px',
                boxShadow: '0px -4px 16px 0px rgba(0, 0, 0, 0.08)',
                padding: 3,
                maxHeight: '300px',
                minHeight: Math.min(maxHeight, sortedUsers.length * 56 + 40),
                overflow: 'hidden',
                width: `${width}px`,
                left: 0,
                backgroundColor: 'white',
            }}
            onClick={(event) => {
                event.stopPropagation()
            }}
        >
            <StyledVirtuoso
                totalCount={sortedUsers.length}
                itemContent={(index) => {
                    const user = sortedUsers[index]

                    return (
                        <UserListItemRow
                            key={user.id}
                            isFirstItem={index === 0}
                            isClickable={true}
                            user={user}
                            onClick={() => onUserClicked(user.id)}
                            endLabel={!user.isGroupMember ? <NotInGroupIcon /> : undefined}
                        />
                    )
                }}
                overscan={500}
                style={{
                    height: Math.min(maxHeight, sortedUsers.length * 56 + 40),
                    width: width - 32,
                    marginTop: '-24px',
                    marginBottom: '-24px',
                    ...hideScrollbar,
                }}
            />
        </Box>
    )
}
