import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Event as DonkeyEvent } from '../Event'
import { eventRepository } from '../..'
import { GroupEventKey, GroupUpdatedEvent } from '../../groups/events'

export const useEvent = (eventId: string) => {
    const location = useLocation()

    const parsedEvent = useMemo(() => {
        const parsedEventFromState = location.state?.event
            ? JSON.parse(location.state.event)
            : undefined
        return parsedEventFromState ? DonkeyEvent.fromJSON(parsedEventFromState) : undefined
    }, [location.state?.event])

    const abortControllerRef = useRef<AbortController>()

    const [isLoading, setIsLoading] = useState(!parsedEvent)
    const [error, setError] = useState<Error | undefined>()
    const [event, setEvent] = useState<DonkeyEvent | undefined>(parsedEvent)

    const getEvent = useCallback(() => {
        setIsLoading(true)

        eventRepository
            .getEvent(eventId, abortControllerRef.current?.signal)
            .then(setEvent)
            .catch(setError)
            .finally(() => setIsLoading(false))
    }, [eventId])

    useEffect(() => {
        abortControllerRef.current?.abort()
        abortControllerRef.current = new AbortController()

        if (parsedEvent) {
            setEvent(parsedEvent)
        }

        getEvent()

        return () => {
            abortControllerRef.current?.abort()
        }
    }, [eventId, parsedEvent, getEvent])

    useEffect(() => {
        const onGroupUpdated = (ev: Event) => {
            const { id, name } = (ev as GroupUpdatedEvent).detail

            if (event && event?.groupId === id) {
                setEvent(
                    event.cloneWith({
                        group: {
                            ...event.group,
                            name,
                        },
                    })
                )
            }
        }

        document.addEventListener(GroupEventKey.GROUP_UPDATED, onGroupUpdated)

        return () => {
            document.removeEventListener(GroupEventKey.GROUP_UPDATED, onGroupUpdated)
        }
    }, [event])

    return { isLoading, error, event, getEvent, setEvent }
}
