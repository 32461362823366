import { LocalPdf } from 'shared/lib/forms/LocalPdf'
import { DateTime } from 'luxon'

export class CreateEventBody {
    constructor(
        readonly title: string,
        readonly start: DateTime,
        readonly end: DateTime,
        readonly isAllDay: boolean,
        readonly description: string,
        readonly location: string,
        readonly groupId: string,
        readonly localPdfs?: LocalPdf[]
    ) {}

    public toFormData() {
        const formData = new FormData()
        const localPdfs = this.localPdfs
        formData.append('title', this.title)
        formData.append('start', this.start.toString())
        formData.append('end', this.end.toString())
        formData.append('isAllDay', `${this.isAllDay}`)
        formData.append('description', this.description)
        formData.append('location', this.location)
        formData.append('groupId', this.groupId)
        localPdfs?.forEach((pdf) => {
            formData.append('pdfFiles', pdf.file)
            formData.append('pdfImageFiles', pdf.thumbnail.file)
        })
        return formData
    }
}


