import { Box, styled, Typography } from '@mui/material'
import { grey_1 } from 'shared/lib/theme/Theme'
import { Pdf } from 'shared/lib/models/Pdf'
import { useTranslation } from 'shared/lib/i18n'
import { ReactComponent as PdfIcon } from '../../assets/svg/attachment.svg'
import { LocalPdf } from 'shared/lib/forms/LocalPdf'
import { MAX_PDF_SIZE_BYTES } from 'shared/lib/common/Constants'
import path from 'path'
import { PdfView } from 'shared/lib/components/PdfView'
import { fileRepository } from '../../index'
import { generatePdfThumbnail } from 'shared/lib/utils/PdfUtil'
import { LocalImage } from 'shared/lib/forms/LocalImage'
import { memo } from 'react'

interface Properties {
    pdfs: Pdf[]
    selectedPdfs: LocalPdf[]

    onDeletePdfButtonClicked(pdf: Pdf | LocalPdf): void
    onPdfsSelected(pdfs: LocalPdf[]): void
}

const Component = (properties: Properties) => {
    const translations = useTranslation()
    const { pdfs, selectedPdfs } = properties

    const onInputClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        const element = event.target as HTMLInputElement
        element.value = ''
    }

    const handleFileInputChange = async (fileList: FileList | null) => {
        if (fileList) {
            const localPdfs: LocalPdf[] = []
            for (let i = 0; i < fileList.length; i++) {
                const pdfFile = fileList[i]
                const thumbnailBlob = await generatePdfThumbnail(pdfFile)
                const thumbnailFileName = pdfFile.name.replace(path.extname(pdfFile.name), '.png')
                const thumbnail = new LocalImage(
                    new File([thumbnailBlob], thumbnailFileName, { type: 'image/png' }),
                    56,
                    56
                )
                selectedPdfs.push(new LocalPdf(pdfFile, thumbnail))
            }

            properties.onPdfsSelected(selectedPdfs.concat(localPdfs))
        }
        return
    }

    return (
        <>
            <input
                accept="application/pdf"
                style={{ display: 'none' }}
                id="pdf"
                type="file"
                multiple={true}
                onClick={onInputClick}
                onChange={(event) => handleFileInputChange(event.target.files)}
            />

            <Box display="flex" py={2}>
                <label htmlFor="pdf">
                    <Box display={'flex'} alignItems={'center'}>
                        <AddPdfIcon />
                        <TextLabel>{translations('add_attachment_pdf')}</TextLabel>
                    </Box>
                </label>
            </Box>

            {pdfs.map((pdf) => (
                <Box mb={2} key={pdf.key}>
                    <PdfView
                        pdf={pdf}
                        onDeleteButtonClicked={properties.onDeletePdfButtonClicked}
                        hasExceeded={pdf.size > MAX_PDF_SIZE_BYTES}
                        fileRepository={fileRepository}
                        translations={translations}
                    />
                </Box>
            ))}

            {selectedPdfs.map((pdf) => (
                <Box mb={2} key={pdf.id}>
                    <PdfView
                        uploadedPdf={pdf}
                        onDeleteButtonClicked={properties.onDeletePdfButtonClicked}
                        hasExceeded={pdf.file.size > MAX_PDF_SIZE_BYTES}
                        fileRepository={fileRepository}
                        translations={translations}
                    />
                </Box>
            ))}
        </>
    )
}

export const EventPdfsView = memo(Component, (previous, next) => {
    return (
        previous.pdfs === next.pdfs &&
        previous.selectedPdfs === next.selectedPdfs &&
        previous.onDeletePdfButtonClicked === next.onDeletePdfButtonClicked &&
        previous.onPdfsSelected === next.onPdfsSelected
    )
})

const TextLabel = styled(Typography)({
    marginLeft: '8px',
    color: grey_1,
    fontSize: '14px',
    cursor: 'pointer',
})

const AddPdfIcon = styled(PdfIcon)(({ theme }) => ({
    fill: theme.palette.primary.main,
    cursor: 'pointer',
}))
