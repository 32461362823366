import { LikeType } from './LikeType'
import { MinimalUserInfo } from '../../user/User'

export class Like {
    constructor(public readonly creator: MinimalUserInfo, public readonly type: LikeType) {}

    static fromJSON(json: Record<string, any>) {
        return new Like(
            {
                id: json.userId,
                image: json.image,
                functions: json.functions,
                firstName: json.firstName,
                lastName: json.lastName,
            },
            json.type
        )
    }
}
