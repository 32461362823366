import { Box, ChipProps, Chip as MuiChip, Typography, TypographyProps, styled } from '@mui/material'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ReactComponent as AlertIcon } from 'shared/lib/assets/svg/alert.svg'
import { AlertDialog } from 'shared/lib/components/AlertDialog'
import { ErrorHandler } from 'shared/lib/components/ErrorHandler'
import { ButtonType } from 'shared/lib/components/buttons/ButtonType'
import { errorColor, grey_1, grey_3 } from 'shared/lib/theme/Theme'
import { capitalizeFirstLetter, escapeMetaCharacters } from 'shared/lib/utils/StringUtils'
import { eventRepository, fileRepository } from '../..'
import { ReactComponent as AttachmentIcon } from '../../assets/svg/attachment.svg'
import { ReactComponent as LocationIcon } from '../../assets/svg/location_outlined.svg'
import { useDetailView } from '../../common/detailView/hooks'
import { MarkdownTextView } from 'shared/lib/components/message/MarkdownTextView'
import { useTranslation } from 'shared/lib/i18n'
import { EventItemViewModel } from '../EventViewModel'
import { EventActionsButton } from '../components/EventActionsButton'
import { EventListItem } from '../components/EventListComponents'
import { EditEventPopup } from '../createOrEdit/EditEventPopup'

interface Properties {
    isLastItem: boolean
    viewModel: EventItemViewModel
}

export const EventItemRow = ({ isLastItem, viewModel }: Properties) => {
    const translations = useTranslation()
    const location = useLocation()

    const { isDetailViewOpen, openGroupDetailView, openEventDetailView } = useDetailView()

    const [isEditPopupVisible, setIsEditPopupVisible] = useState(false)
    const [isDeletePopupVisible, setIsDeletePopupVisible] = useState(false)
    const [isDeletingEvent, setIsDeletingEvent] = useState(false)
    const [error, setError] = useState<Error | undefined>()

    const { isFirstEventOnSameDay, isLastEventOnSameDay, isGroupNameVisible, event } = viewModel
    const { start, end, numberOfDaysFromInitialStart, totalNumberOfDaysFromInitialStart } = event

    const isSelected = location.search.includes(event.id) && isDetailViewOpen
    const hasChips = event.location || event.pdfs.length > 0
    const description = escapeMetaCharacters(event.description)

    const SelectionIndicator = () => {
        return (
            <SelectedIndicatorContainer height="100px" mt={isFirstEventOnSameDay ? 0 : '-22px'}>
                <Box height="24px" width="24px" borderRadius="0 0 16px 0" bgcolor={grey_3} />
                <Box height="56px" />
                <Box height="24px" width="24px" borderRadius="0 16px 0 0" bgcolor={grey_3} />
            </SelectedIndicatorContainer>
        )
    }

    const getBorderRadius = () => {
        switch (true) {
            case isFirstEventOnSameDay && isLastEventOnSameDay:
                return isSelected ? '16px 0 16px 16px' : '16px'
            case isFirstEventOnSameDay:
                return isSelected ? '16px 0 0 0' : '16px 16px 0 0'
            case isLastEventOnSameDay:
                return '0 0 16px 16px'
            default:
                return ''
        }
    }

    const getStartTime = () => {
        switch (true) {
            case event.isAllDay:
                return translations('agenda_all_day')
            case event.hasDurationOfMultipleDays:
                const isFirstDay = numberOfDaysFromInitialStart === 0
                const isLastDay = numberOfDaysFromInitialStart === totalNumberOfDaysFromInitialStart
                return isFirstDay
                    ? `${capitalizeFirstLetter(translations('from'))} ${start.toLocaleString(
                          DateTime.TIME_24_SIMPLE
                      )}`
                    : isLastDay
                      ? `${capitalizeFirstLetter(translations('to'))} ${end.toLocaleString(
                            DateTime.TIME_24_SIMPLE
                        )}`
                      : translations('agenda_all_day')
            default:
                return start.toLocaleString(DateTime.TIME_24_SIMPLE)
        }
    }

    const getEndTime = () => {
        switch (true) {
            case event.isAllDay:
                return ''
            case event.hasDurationOfMultipleDays:
                const numberOfDays = numberOfDaysFromInitialStart + 1
                const totalNumberOfDays = totalNumberOfDaysFromInitialStart + 1
                return translations('day_slash_days', [numberOfDays, totalNumberOfDays])
            default:
                return end.toLocaleString(DateTime.TIME_24_SIMPLE)
        }
    }

    const deleteEvent = async () => {
        setIsDeletingEvent(true)

        eventRepository
            .deleteEvent(event.id)
            .catch(setError)
            .finally(() => {
                setIsDeletingEvent(false)
                setIsDeletePopupVisible(false)
            })
    }

    const onGroupNameClicked = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        e.stopPropagation()

        openGroupDetailView(event.groupId, { resetHistory: true })
    }

    return (
        <>
            <EventListItem isLastItem={isLastItem} hasLargePaddingTop={isFirstEventOnSameDay}>
                <Container onClick={() => openEventDetailView({ event })}>
                    <TimeContainer>
                        <Typography variant="body1">{getStartTime()}</Typography>
                        {<Typography variant="body2">{getEndTime()}</Typography>}
                    </TimeContainer>

                    <EventContainer borderRadius={getBorderRadius()}>
                        <Box display="flex" flexDirection="row" flexGrow={1}>
                            <TextContainer mr={event.canEdit ? 1 : 0}>
                                {isGroupNameVisible && (
                                    <Box display="flex">
                                        <GroupNameTextView
                                            isClickable={!event.group.isHome}
                                            onClick={onGroupNameClicked}
                                        >
                                            {event.group.name}
                                        </GroupNameTextView>
                                    </Box>
                                )}
                                <TitleTextView variant="body1">{event.title}</TitleTextView>

                                <MarkdownTextView
                                    maxLines={2}
                                    translations={{
                                        readLess: translations('read_less'),
                                    }}
                                >
                                    {description}
                                </MarkdownTextView>
                            </TextContainer>

                            {event.canEdit && (
                                <EventActionsButton
                                    event={event}
                                    onEditButtonClicked={() => setIsEditPopupVisible(true)}
                                    onDeleteButtonClicked={() => setIsDeletePopupVisible(true)}
                                />
                            )}
                        </Box>

                        {hasChips && (
                            <Box display="flex" flexDirection="row" flexWrap="wrap" mt={3} gap={1}>
                                {event.location && (
                                    <Chip
                                        key="location"
                                        href={`https://www.google.com/maps/search/?api=1&query=${event.location}`}
                                        icon={<LocationIcon />}
                                        label={event.location}
                                    />
                                )}
                                {event.pdfs.map((pdf, index) => (
                                    <Chip
                                        key={index}
                                        href={fileRepository.getFileUrl(pdf.key)}
                                        icon={<AttachmentIcon />}
                                        label={pdf.name}
                                    />
                                ))}
                            </Box>
                        )}
                    </EventContainer>
                </Container>

                {isSelected && <SelectionIndicator />}
            </EventListItem>

            {isEditPopupVisible && (
                <EditEventPopup
                    event={event}
                    onCloseButtonClicked={() => setIsEditPopupVisible(false)}
                    onEventUpdated={(event) => setIsEditPopupVisible(false)}
                />
            )}

            {isDeletePopupVisible && (
                <AlertDialog
                    isLoading={isDeletingEvent}
                    isVisible={true}
                    titleIcon={<AlertIcon fill={errorColor} />}
                    title={translations('are_you_sure')}
                    message={translations('delete_event_message')}
                    cancelButtonTitle={translations('cancel')}
                    onCancelButtonClicked={() => setIsDeletePopupVisible(false)}
                    continueButtonType={ButtonType.RED}
                    continueButtonTitle={translations('delete_event')}
                    onContinueButtonClicked={deleteEvent}
                />
            )}

            <ErrorHandler error={error} translations={translations} />
        </>
    )
}

const Container = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
}))

const TimeContainer = styled(Box)(({ theme }) => ({
    width: '64px',
    minWidth: '64px',
    minHeight: '88px',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(3),
}))

const EventContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundColor: 'white',
    overflow: 'hidden',
    marginLeft: theme.spacing(1),
    padding: theme.spacing(3),

    '& svg': {
        fill: grey_1,
    },
}))

const TextContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden',
}))

const GroupNameTextView = styled(
    ({ isClickable, ...properties }: TypographyProps & { isClickable: boolean }) => (
        <Typography variant="body2" {...properties} />
    )
)(({ theme, isClickable }) => ({
    fontSize: '12px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: isClickable ? theme.palette.primary.main : theme.typography.body2.color,
    textOverflow: 'ellipsis',
    cursor: isClickable ? 'pointer' : 'default',
    pointerEvents: isClickable ? 'auto' : 'none',
}))

const TitleTextView = styled(Typography)(() => ({
    fontWeight: 700,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}))

const Chip = styled((properties: ChipProps<'a'>) => (
    <MuiChip
        clickable={true}
        component="a"
        rel="noreferrer"
        target="_blank"
        onClick={(event) => event.stopPropagation()}
        {...properties}
    />
))(({ theme }) => ({
    '& svg': {
        height: '16px',
        width: '16px',
        fill: theme.palette.text.primary,
        marginLeft: `${theme.spacing(1)} !important`,
    },
}))

const SelectedIndicatorContainer = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    right: 0,
    width: theme.spacing(3),
    backgroundColor: 'white',
}))
