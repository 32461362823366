import { Box, Paper as MuiPaper, PaperProps, styled } from '@mui/material'
import { PropsWithChildren } from 'react'
import { DonkeyBackdrop } from './Backdrop'

interface Properties {
    isVisible: boolean
    withoutBackdrop?: boolean
    autoSizeHeight?: boolean
    desktopWidth?: string
}

export const Popup = (properties: PropsWithChildren<Properties>) => {
    return (
        <DonkeyBackdrop
            isVisible={properties.isVisible}
            withoutBackdrop={properties.withoutBackdrop}
        >
            <Paper
                elevation={0}
                desktopWidth={properties.desktopWidth}
                autoSizeHeight={properties.autoSizeHeight}
            >
                <PaperContent>{properties.children}</PaperContent>
            </Paper>
        </DonkeyBackdrop>
    )
}

const Paper = styled(
    ({
        desktopWidth,
        autoSizeHeight,
        ...properties
    }: PaperProps & { desktopWidth?: string; autoSizeHeight?: boolean }) => (
        <MuiPaper {...properties} />
    )
)(({ desktopWidth, autoSizeHeight, theme }) => ({
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 32,
    backgroundColor: 'white',
    overflow: 'hidden',

    [theme.breakpoints.only('desktop')]: {
        minWidth: '600px',
        width: desktopWidth || '25%',
        height: autoSizeHeight ? 'auto' : '70%',
    },

    [theme.breakpoints.only('phablet')]: {
        width: '80%',
        height: autoSizeHeight ? 'auto' : '90%',
    },

    [theme.breakpoints.only('tablet')]: {
        width: '60%',
        height: autoSizeHeight ? 'auto' : '80%',
    },

    [theme.breakpoints.only('mobile')]: {
        width: '100%',
        height: '100dvh',
        borderRadius: 0,
    },
}))

const PaperContent = styled(Box)(() => ({
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
}))
