import {
    GreenContainedButton,
    InfoContainedButton,
    PrimaryContainedButton,
} from './ContainedButtons'
import { RedContainedButton } from './RedButtons'
import { YellowContainedButton } from './YellowButtons'

export enum ButtonType {
    NONE,
    YELLOW,
    RED,
    GREEN,
    INFO,
}

interface Properties {
    isLoading?: boolean
    isDisabled?: boolean
    type?: ButtonType
    title: string
    height?: any
    width?: any
    onButtonClicked: () => void
}

export const ButtonByType = (properties: Properties) => {
    const sx = { height: properties.height, width: properties.width }

    switch (properties.type) {
        case ButtonType.GREEN:
            return (
                <GreenContainedButton
                    isLoading={properties.isLoading}
                    disabled={properties.isDisabled}
                    title={properties.title}
                    sx={sx}
                    onClick={properties.onButtonClicked}
                />
            )
        case ButtonType.YELLOW:
            return (
                <YellowContainedButton
                    isLoading={properties.isLoading}
                    disabled={properties.isDisabled}
                    title={properties.title}
                    sx={sx}
                    onClick={properties.onButtonClicked}
                />
            )
        case ButtonType.RED:
            return (
                <RedContainedButton
                    isLoading={properties.isLoading}
                    disabled={properties.isDisabled}
                    title={properties.title}
                    sx={sx}
                    onClick={properties.onButtonClicked}
                />
            )
        case ButtonType.INFO:
            return (
                <InfoContainedButton
                    isLoading={properties.isLoading}
                    disabled={properties.isDisabled}
                    title={properties.title}
                    sx={sx}
                    onClick={properties.onButtonClicked}
                />
            )
        case ButtonType.NONE:
        default:
            return (
                <PrimaryContainedButton
                    isLoading={properties.isLoading}
                    disabled={properties.isDisabled}
                    title={properties.title}
                    sx={sx}
                    onClick={properties.onButtonClicked}
                />
            )
    }
}
