import { DateTime } from 'luxon'
import { useTranslation } from 'shared/lib/i18n'
import i18n from '../../../i18n'
import { Event } from '../../Event'
import { FromToLabel } from './FromToLabel'

export const StartEndDateMultiDay = ({ event, show }: { event: Event; show: 'start' | 'end' }) => {
    const translations = useTranslation()
    const dateToUse = event[show === 'start' ? 'initialStart' : 'end']
    const translationsKey = show === 'start' ? 'from' : 'to'

    if (event.isSameDay) {
        return null
    }

    if (event.isAllDay) {
        return (
            <FromToLabel>
                {translations(translationsKey)}&nbsp;
                <span>
                    {dateToUse.toLocaleString(
                        {
                            weekday: 'long',
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric',
                        },
                        {
                            locale: i18n.language,
                        }
                    )}
                </span>
            </FromToLabel>
        )
    }

    return (
        <FromToLabel>
            {translations(translationsKey)}&nbsp;
            <span>
                {dateToUse.toLocaleString(
                    {
                        weekday: 'short',
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                    },
                    {
                        locale: i18n.language,
                    }
                )}
            </span>
            &nbsp;&#183;&nbsp;
            <span>{dateToUse.toLocaleString(DateTime.TIME_24_SIMPLE)}</span>
        </FromToLabel>
    )
}
