import { styled } from '@mui/material'
import { DateTime } from 'luxon'
import { DatePicker as MuiDatePicker, DatePickerProps } from '@mui/x-date-pickers'

export const DatePicker = styled(
    ({
        helperText,
        ...properties
    }: DatePickerProps<DateTime> & { helperText?: React.ReactNode }) => (
        <MuiDatePicker
            slotProps={{
                desktopPaper: {
                    sx: {
                        padding: 0,
                        borderRadius: '16px',
                    },
                },
                mobilePaper: {
                    sx: {
                        padding: 0,
                    },
                },
                textField: {
                    variant: 'filled',
                    helperText,
                },
                day: {
                    sx: {
                        '&.MuiPickersDay-root.Mui-selected': {
                            color: 'white !important',
                        },
                    },
                },
            }}
            {...properties}
        />
    )
)(({ theme }) => ({
    width: '100%',
    '& .MuiFilledInput-root': {
        '&:before, &:after': {
            borderBottom: 'none',
        },
        '&:hover:before': {
            borderBottom: 'none !important',
        },
        '&.Mui-focused:before, &.Mui-focused:after': {
            borderBottom: 'none',
        },

        '&.MuiInputAdornment-positionEnd': {
            marginRight: theme.spacing(1),
        },
    },
}))
