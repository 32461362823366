import { User } from '../user/User'
import { Collapse, List as MuiList, styled } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'shared/lib/i18n'
import { useDetailView } from '../common/detailView/hooks'
import { useUsers } from '../user/hooks'
import { SeeAllButton } from '../common/lists/SeeAllButton'
import { sortUsersByLastName } from '../user/Utils'
import { getFullName } from 'shared/lib/utils/StringUtils'
import { UnapprovedUserListItem } from '../common/lists/UnapprovedUserListItem'
import { AlertDialog } from 'shared/lib/components/AlertDialog'
import { ErrorHandler } from 'shared/lib/components/ErrorHandler'
import { ButtonType } from 'shared/lib/components/buttons/ButtonType'
import { UserListLoadingRow } from '../user/userList/UserListItemRows'
import { errorColor } from 'shared/lib/theme/Theme'
import { ReactComponent as AlertIcon } from 'shared/lib/assets/svg/alert.svg'

interface Properties {
    users: User[]
    maxUsersToShow?: number
    isLoading?: boolean
}

export const AppAccessRequestsList = ({ isLoading, ...properties }: Properties) => {
    const translations = useTranslation()

    const { users, onApproveUser, onDenyUser, error } = useUsers({
        isApproved: false,
        initialValue: properties.users,
    })

    const { openAppAccessRequestsView } = useDetailView()

    const [userToDeny, setUserToDeny] = useState<User | undefined>()

    const sortedUsers = sortUsersByLastName(users)
    const visibleUsers = sortedUsers.map((groupMember) => groupMember.id)

    const rows = isLoading
        ? [<UserListLoadingRow key={0} />, <UserListLoadingRow key={1} />]
        : sortedUsers
              .slice(0, properties.maxUsersToShow ?? sortedUsers.length)
              .map((userToApprove) => {
                  const shouldShow = !visibleUsers.length || visibleUsers.includes(userToApprove.id)

                  return (
                      <Collapse in={shouldShow} key={userToApprove.id}>
                          <UnapprovedUserListItem
                              userToApprove={userToApprove}
                              propertyToUseForSecondaryLabel="emailAddress"
                              onApprove={onApproveUser}
                              onDeny={() => setUserToDeny(userToApprove)}
                          />
                      </Collapse>
                  )
              })

    const denyUser = () => {
        if (!userToDeny) {
            return
        }

        setUserToDeny(undefined)
        onDenyUser(userToDeny.id)
    }

    return (
        <List>
            {rows}
            {properties.maxUsersToShow && sortedUsers.length > properties.maxUsersToShow && (
                <SeeAllButton
                    translationKey="show_all_access_requests"
                    totalMembersCount={sortedUsers.length}
                    onClick={openAppAccessRequestsView}
                />
            )}
            <AlertDialog
                titleIcon={<AlertIcon fill={errorColor} />}
                isVisible={!!userToDeny}
                title={translations('are_you_sure')}
                message={translations('confirm_deny_user_message', [
                    userToDeny ? getFullName(userToDeny) : '',
                ])}
                continueButtonType={ButtonType.RED}
                continueButtonTitle={translations('deny')}
                cancelButtonTitle={translations('cancel')}
                onContinueButtonClicked={denyUser}
                onCancelButtonClicked={() => setUserToDeny(undefined)}
            />
            <ErrorHandler error={error} translations={translations} horizontal="right" />
        </List>
    )
}

const List = styled(MuiList)(({ theme }) => ({
    width: `calc(100% + ${theme.spacing(2)})`,
    paddingTop: theme.spacing(1),
    marginLeft: theme.spacing(-1),
}))
