import { Event } from '../Event'
import { useCallback, useState } from 'react'
import { EventPopupEvent } from './EventPopup'
import { DateTime } from 'luxon'
import { MAX_PDF_SIZE_BYTES } from 'shared/lib/common/Constants'
import { DateTimeValidator } from 'shared/lib/utils/DateTimeValidator'
import { LocalPdf } from 'shared/lib/forms/LocalPdf'

export const useCreateOrEditEventState = (
    initialState: {
        event?: Event
        selectedPdfs?: LocalPdf[]
    } = {}
) => {
    const [state, setState] = useState<EventPopupEvent>({
        title: initialState.event?.title || '',
        description: initialState.event?.description || '',
        location: initialState.event?.location || '',
        start: initialState.event?.initialStart || DateTime.local().startOf('hour'),
        end: initialState.event?.end || DateTime.local().startOf('hour').plus({ hours: 1 }),
        isAllDay: initialState.event?.isAllDay ?? false,
        pdfs: initialState.event?.pdfs || [],
        selectedPdfs: initialState.selectedPdfs || [],
    })

    const onFieldChanged = useCallback(
        <T extends keyof EventPopupEvent>(field: T) =>
            (value: EventPopupEvent[T]) => {
                setState((prevState) => ({ ...prevState, [field]: value }))
            },
        []
    )

    const hasChanges =
        state.title !== initialState.event?.title ||
        state.description !== initialState.event?.description ||
        state.location !== initialState.event?.location ||
        state.start.toISO() !== initialState.event?.initialStart?.toISO() ||
        state.end.toISO() !== initialState.event?.end?.toISO() ||
        state.isAllDay !== initialState.event?.isAllDay ||
        state.pdfs !== initialState.event?.pdfs ||
        state.selectedPdfs.length > 0

    const canSaveChanges =
        state.title.trim().length > 0 &&
        state.selectedPdfs.every((pdf) => pdf.file.size < MAX_PDF_SIZE_BYTES) &&
        state.start !== undefined &&
        DateTimeValidator.isValidPeriod(state.start, state.end, state.isAllDay)

    return {
        event: state,
        hasChanges,
        canSaveChanges,
        onFieldChanged,
    }
}
