'use client'

import { createTheme } from '@mui/material'
import { createBreakpoints } from '@mui/system'
import { maxWidthMobile, maxWidthPhablet, maxWidthTablet } from './constants'

declare module '@mui/system' {
    interface BreakpointOverrides {
        mobile: true
        phablet: true
        tablet: true
        desktop: true
        xs: false
        sm: false
        md: false
        lg: false
        xl: false
    }
}

// Colors
export const primaryColor = '#ab804b'
export const darkGrey = '#2c302e'
export const lightGrey = '#727272'
export const backgroundColor = '#F4F4F4'
export const paperColor = '#ffffff'
export const errorColor = '#ce5865'
export const errorColorHover = '#e19daa'
export const errorColorDisabled = '#ce58654d'
export const warningColor = '#feca57'
export const warningColorHover = '#fed680'
export const successColor = '#59cd90'
export const successColorBackground = '#daf6e7'
export const successColorHover = '#40B477'
export const disabledPrimaryColor = '#ab804b4d'
export const disabledBlueBackgroundColor = '#4b8cab4d'
export const disabledGreyBackgroundColor = '#727272'

export const disabledGreenColor = '#59cd904d'
export const greenColor = '#59cd90'
export const greenBackgroundColor = '#daf6e7'

export const blueColor = '#4b8cab'
export const blueBackgroundColor = '#dae0f6'
export const orangeColor = '#fd9644'

export const textColor = '#2c302e'
export const grey_1 = '#7f7f7f'
export const grey_2 = '#c9c9c9'
export const grey_3 = '#f4f4f4'

// Text sizes
const textSizeTitle = 22
const textSizeUndertitle = 24
const textSizeCardtitle = 18
const textSizeBodytitle = 17
const textSizeBody = 14

const textWeightHeavy = 800
const textWeightBold = 'bold'
const textWeightNormal = 400
const textWeightThin = 300

// Spacing and heights
export const spacing = 8

export const toolbarHeight = 80
export const mobileToolbarHeight = 56
export const smartBannerHeight = 100
export const mobileGroupTabsHeight = 74
export const bottomBarHeight = 80
export const mobileBottomBarHeight = 56
export const mobileHeaderWithGroupsHeight = mobileToolbarHeight + mobileGroupTabsHeight
export const drawerWidthExpanded = 200
export const drawerWidthCollapsed = 72
export const defaultButtonHeight = 50

// Borders and shadows
export const defaultBorder = `2px solid ${grey_3}`
export const defaultInnerDropShadow = 'inset 0px 0px 16px 0px rgba(0, 0, 0, 0.08)'
export const defaultBoxShadow = '0px 2px 16px 0px rgba(0, 0, 0, 0.08)'
export const defaultHoverState = {
    boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.08)',
}
export const defaultGrey2HoverState = {
    transition: 'background-color 0.2s ease-in-out',

    '&:hover': {
        backgroundColor: `${grey_2}66`,
    },
}

// Transitions
export const defaultTransitionDuration = 300
export const defaultTransition = `all ${defaultTransitionDuration / 1000}s ease-in-out`

// Breakpoints
const breakpoints = createBreakpoints({
    values: {
        mobile: 0,
        phablet: maxWidthMobile,
        tablet: maxWidthPhablet,
        desktop: maxWidthTablet,
    },
})

// Theme
export default createTheme({
    breakpoints: breakpoints,
    palette: {
        primary: {
            main: primaryColor,
        },
        secondary: {
            main: blueColor,
        },
        warning: {
            main: warningColor,
        },
        success: {
            main: successColor,
        },
        info: {
            main: blueColor,
        },
        error: {
            main: errorColor,
        },
        text: {
            primary: darkGrey,
            secondary: grey_1,
        },
        background: {
            default: backgroundColor,
            paper: paperColor,
        },
    },
    spacing,
    typography: {
        fontFamily: ['Lato', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto'].join(','),
        h1: {
            fontWeight: textWeightHeavy,
            fontSize: textSizeTitle,
            color: textColor,
        },
        h2: {
            fontWeight: textWeightThin,
            fontSize: textSizeUndertitle,
            color: textColor,
        },
        h3: {
            fontWeight: textWeightBold,
            fontSize: textSizeCardtitle,
            color: textColor,
        },
        h4: {
            fontWeight: textWeightBold,
            fontSize: textSizeBodytitle,
            color: textColor,
        },
        body1: {
            fontWeight: textWeightNormal,
            fontSize: textSizeBody,
            color: textColor,
        },
        body2: {
            fontWeight: textWeightNormal,
            fontSize: textSizeBody,
            color: grey_1,
        },
    },
    components: {
        MuiAvatar: {
            styleOverrides: {
                root: {
                    backgroundColor: grey_2,
                },
                colorDefault: {
                    backgroundColor: grey_2,
                },
                fallback: {
                    fill: 'white',
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: '16px',
                    padding: '32px',
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontSize: '18px',
                    fontWeight: 'bold',
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    border: 'none',
                    backgroundColor: paperColor,
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: paperColor,
                    color: textColor,
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    '&$focused': {
                        color: '#000',
                    },
                },
                input: {
                    border: '0px solid white',
                },
                focused: {},
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    color: grey_1,
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: textColor,
                    fontSize: 14,
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: grey_3,
                    borderRadius: '16px',
                },
                notchedOutline: {
                    border: '0px solid white',
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    borderRadius: '16px',
                    backgroundColor: grey_3,
                },
                underline: {
                    '&&&:before': {
                        borderBottom: 'none',
                    },
                    '&&:after': {
                        borderBottom: 'none',
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    height: defaultButtonHeight,
                    borderRadius: '16px',
                    textTransform: 'none',
                    fontWeight: 'bold',
                },
                contained: (properties) => ({
                    textTransform: 'none',
                    '&.Mui-disabled': {
                        backgroundColor: `${properties.theme.palette.primary.main}40`,
                        color: 'white',
                    },
                }),
                containedInfo: {
                    '&.Mui-disabled': {
                        backgroundColor: disabledBlueBackgroundColor,
                        color: 'white',
                    },
                },
                text: {
                    paddingLeft: spacing * 2,
                    paddingRight: spacing * 2,
                },
            },
        },
        MuiStepper: {
            styleOverrides: {
                root: {
                    padding: '16px 0px 16px 0px',
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: 'white',
                    color: textColor,
                    fontSize: textSizeBody,
                    padding: '16px',
                    borderRadius: '24px',
                    boxShadow: '0 0 25px 0 rgba(0, 0, 0, 0.1)',
                },
                arrow: {
                    color: 'white',
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    textDecoration: 'none',
                },
            },
        },
        MuiSnackbar: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    [breakpoints.not('mobile')]: {
                        ...(ownerState?.anchorOrigin?.vertical === 'top'
                            ? { top: 24 }
                            : { bottom: 24 }),
                        ...(ownerState?.anchorOrigin?.horizontal === 'center' && {
                            left: '50%',
                            right: 'auto',
                            transform: 'translateX(-50%)',
                        }),
                        ...(ownerState?.anchorOrigin?.horizontal === 'left' && {
                            left: 24,
                            right: 'auto',
                        }),
                        ...(ownerState?.anchorOrigin?.horizontal === 'right' && {
                            right: 24,
                            left: 'auto',
                        }),
                    },
                }),
            },
        },
        MuiSkeleton: {
            styleOverrides: {
                text: {
                    borderRadius: '8px',
                },
                rounded: {
                    borderRadius: '16px',
                },
            },
        },
    },
})
