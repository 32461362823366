import { Backdrop, Dialog as MuiDialog, styled } from '@mui/material'
import { PropsWithChildren } from 'react'

interface DonkeyBackdropProperties {
    isVisible: boolean
    withoutBackdrop?: boolean
}

export const DonkeyBackdrop = (properties: PropsWithChildren<DonkeyBackdropProperties>) => {
    return (
        <Dialog
            open={properties.isVisible}
            sx={{
                '& .MuiBackdrop-root': {
                    backgroundColor: properties.withoutBackdrop ? 'transparent' : undefined,
                },
            }}
        >
            <Backdrop open={properties.isVisible} invisible>
                {properties.children}
            </Backdrop>
        </Dialog>
    )
}

const Dialog = styled(MuiDialog)(() => ({
    '& .MuiDialog-paper': {
        padding: 0,
    },
}))
