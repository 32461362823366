import { Typography, TypographyProps } from '@mui/material'
import { DateTime } from 'luxon'
import { useTranslation } from 'shared/lib/i18n'
import { Event } from '../../Event'
import { FromToLabel } from './FromToLabel'

export const TimeTextView = ({ event }: { event: Event }) => {
    const translations = useTranslation()

    if (!event.isSameDay) {
        return null
    }

    return event.isAllDay ? (
        <Typography variant="body2">{translations('agenda_all_day')}</Typography>
    ) : (
        <StyledDateLabel>
            {translations('from_to', [
                <span>{event.start.toLocaleString(DateTime.TIME_24_SIMPLE)}</span>,
                <span>{event.end.toLocaleString(DateTime.TIME_24_SIMPLE)}</span>,
            ])}
        </StyledDateLabel>
    )
}

const StyledDateLabel = ({ children, ...props }: TypographyProps) => (
    <FromToLabel
        dangerouslySetInnerHTML={{
            __html: children as string,
        }}
        {...props}
    />
)
