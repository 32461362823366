import { SessionRepository } from '../../authentication/SessionRepository'
import { WebAppHttpRepository } from '../../common/WebAppHttpRepository'
import { Event } from '../Event'
import { EventCreatedEvent, EventDeletedEvent, EventUpdatedEvent } from '../events'
import { CreateEventBody } from './CreateEventBody'
import { UpdateEventBody } from './UpdateEventBody'

export class EventRepository extends WebAppHttpRepository {
    constructor(private readonly sessionRepository: SessionRepository) {
        super()
    }

    async getEvents(
        params: {
            groupId?: string
            from?: string
        },
        abortSignal?: AbortSignal
    ): Promise<{ events: Event[]; nextEventStart: string }> {
        const fromParam = params.from ? `from=${params.from}` : ''
        const groupIdParam = params.groupId ? `groupId=${params.groupId}` : ''
        const questionMark = fromParam || groupIdParam ? '?' : ''
        const ampersand = fromParam && groupIdParam ? '&' : ''
        const response = await this.sessionRepository.withAccessToken(async () =>
            this.get<any>(
                `${this.apiUrl}/api/v2/events${questionMark}${fromParam}${ampersand}${groupIdParam}`,
                abortSignal
            )
        )

        return {
            events: response.events.map(Event.fromJSON),
            nextEventStart: response.nextEventStart,
        }
    }

    async createEvent(body: CreateEventBody): Promise<Event> {
        const response = await this.sessionRepository.withAccessToken(async () =>
            this.post<any>(`${this.apiUrl}/api/v1/events`, body.toFormData())
        )

        const createdEvent = Event.fromJSON(response)

        document.dispatchEvent(new EventCreatedEvent(createdEvent))

        return createdEvent
    }

    async getEvent(eventId: string, abortSignal?: AbortSignal): Promise<Event> {
        const response = await this.sessionRepository.withAccessToken(async () =>
            this.get<any>(`${this.apiUrl}/api/v2/events/${eventId}`, abortSignal)
        )

        return Event.fromJSON(response)
    }

    async updateEvent(eventId: string, body: UpdateEventBody): Promise<Event> {
        const response = await this.sessionRepository.withAccessToken(async () =>
            this.patch<any>(`${this.apiUrl}/api/v1/events/${eventId}`, body.toFormData())
        )

        const updatedEvent = Event.fromJSON(response)

        document.dispatchEvent(new EventUpdatedEvent(updatedEvent))

        return updatedEvent
    }

    async deleteEvent(eventId: string): Promise<void> {
        await this.sessionRepository.withAccessToken(async () =>
            this.delete(`${this.apiUrl}/api/v1/events/${eventId}`)
        )

        document.dispatchEvent(new EventDeletedEvent(eventId))
    }
}
