import { Box, Link as MuiLink, Typography, styled } from '@mui/material'
import { HorizontalDivider as Divider } from 'shared/lib/components/Divider'
import { PdfView } from 'shared/lib/components/PdfView'
import { Pdf } from 'shared/lib/models/Pdf'
import { grey_1 } from 'shared/lib/theme/Theme'
import { escapeMetaCharacters } from 'shared/lib/utils/StringUtils'
import { ReactComponent as ClockIcon } from '../../assets/svg/clock.svg'
import { ReactComponent as DescriptionIcon } from '../../assets/svg/description.svg'
import { ReactComponent as LocationIcon } from '../../assets/svg/location_outlined.svg'
import { useDetailView } from '../../common/detailView/hooks'
import { MessageView } from '../../components/message/MessageView'
import { useTranslation } from 'shared/lib/i18n'
import { fileRepository } from '../../index'
import { Event } from '../Event'
import {
    ContentSectionContainer,
    HeaderContainer,
    StartDateSingleDay,
    StartEndDateMultiDay,
    TimeTextView,
} from './components'

export const EventContentView = ({ event }: { event: Event }) => {
    const translations = useTranslation()
    const { openGroupDetailView } = useDetailView()

    const description = escapeMetaCharacters(event.description)

    const onClickGroup = () => {
        openGroupDetailView(event!.groupId)
    }

    return (
        <>
            <HeaderContainer>
                <Header variant="h3">{event.title}</Header>
                <SubHeader>
                    {translations('calendar_of')}&nbsp;
                    {event.group.isHome ? (
                        event.group.name
                    ) : (
                        <Link onClick={onClickGroup}>{event.group.name}</Link>
                    )}
                </SubHeader>
            </HeaderContainer>
            <HorizontalDivider />
            <ContentSectionContainer>
                <ClockIcon />
                <Box display="flex" flexDirection="column" alignItems="start">
                    <StartEndDateMultiDay event={event} show="start" />
                    <StartDateSingleDay event={event} />
                    <StartEndDateMultiDay event={event} show="end" />
                    <TimeTextView event={event} />
                </Box>
            </ContentSectionContainer>
            {event.location ? (
                <>
                    <HorizontalDivider />
                    <LocationSectionContainer>
                        <LocationIcon />
                        <Link
                            href={`https://www.google.com/maps/search/?api=1&query=${event.location}`}
                            target="_blank"
                        >
                            {event.location}
                        </Link>
                    </LocationSectionContainer>
                </>
            ) : null}
            {event.description ? (
                <>
                    <HorizontalDivider />
                    <ContentSectionContainer>
                        <DescriptionIcon />
                        <MessageView
                            details={{
                                id: event.id,
                                hasMarkDown: true,
                                getMessage: () => description,
                            }}
                            linesToShowWhenCollapsed={16}
                        />
                    </ContentSectionContainer>
                </>
            ) : null}
            {event.pdfs.length ? (
                <ContentSectionContainer>
                    <PdfsContainer>
                        {event.pdfs.map((pdf: Pdf) => (
                            <PdfView
                                key={pdf.key}
                                pdf={pdf}
                                translations={translations}
                                fileRepository={fileRepository}
                            />
                        ))}
                    </PdfsContainer>
                </ContentSectionContainer>
            ) : null}
        </>
    )
}

const LocationSectionContainer = styled(ContentSectionContainer)(({ theme }) => ({
    p: {
        color: theme.palette.primary.main,
    },

    '& > svg': {
        fill: theme.palette.primary.main,
    },
}))

const HorizontalDivider = styled(Divider)(() => ({
    width: '100%',
}))

const Header = styled(Typography)(() => ({
    textAlign: 'left',
}))

const SubHeader = styled(Typography)(() => ({
    color: grey_1,
}))

const Link = styled(MuiLink)(() => ({
    cursor: 'pointer',
}))

const PdfsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '100%',
}))
