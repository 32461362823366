import { SessionRepository } from '../authentication/SessionRepository'
import { WebAppHttpRepository } from '../common/WebAppHttpRepository'
import { User } from './User'
import { UpdateUserParams, UpdateUserParamsToFormData } from './models/UpdateUserParams'

export class UserRepository extends WebAppHttpRepository {
    constructor(private readonly sessionRepository: SessionRepository) {
        super()
    }

    async getUsers(
        { isApproved }: { isApproved: boolean },
        abortSignal?: AbortSignal
    ): Promise<User[]> {
        if (!this.sessionRepository.canSeeUsers) {
            return []
        }

        return (
            await this.sessionRepository.withAccessToken(() =>
                this.get<Record<string, any>[]>(
                    `${this.apiUrl}/api/v2/users?isApproved=${isApproved.toString()}`,
                    abortSignal
                )
            )
        ).map(User.fromApiResponse)
    }

    async getUser(userId: string): Promise<User> {
        const response = await this.sessionRepository.withAccessToken(() =>
            this.get<Record<string, any>>(`${this.apiUrl}/api/v1/users/${userId}`)
        )

        return User.fromApiResponse(response)
    }

    async updateUser(userId: string, params: UpdateUserParams): Promise<User> {
        return this.patch(
            `${this.apiUrl}/api/v2/users/${userId}`,
            UpdateUserParamsToFormData(params)
        )
    }

    async denyUser(userId: string): Promise<void> {
        await this.sessionRepository.withAccessToken(() =>
            this.patch(`${this.apiUrl}/api/v1/users/${userId}/deny`)
        )
    }

    async approveUser(userId: string): Promise<void> {
        await this.sessionRepository.withAccessToken(() =>
            this.patch(`${this.apiUrl}/api/v1/users/${userId}/approve`)
        )
    }

    async hasBalance(userId: string): Promise<boolean> {
        const response = await this.sessionRepository.withAccessToken(() =>
            this.get<Record<string, any>>(`${this.apiUrl}/api/v1/admin/users/${userId}/hasBalance`)
        )

        return response.hasBalance
    }

    async deleteUser(userId: string): Promise<void> {
        await this.sessionRepository.withAccessToken(() =>
            this.delete(`${this.apiUrl}/api/v1/users/${userId}`)
        )
    }
}
