// Colors
export const primaryColor = '#ab804b'
export const primaryDarkColor = '#775934'
export const darkGrey = '#2c302e'
export const lightGrey = '#727272'
export const backgroundGrey = '#FAFAFA'
export const backgroundColor = '#F4F4F4'
export const paperColor = '#ffffff'
export const errorColor = '#ce5865'
export const errorColorHover = '#e19daa'
export const errorColorDisabled = '#ce58654d'
export const warningColor = '#feca57'
export const warningColorHover = '#fed680'
export const successColor = '#59cd90'
export const successColorBackground = '#daf6e7'
export const successColorHover = '#40B477'
export const disabledPrimaryColor = '#ab804b4d'
export const disabledBlueBackgroundColor = '#4b8cab4d'
export const disabledGreyBackgroundColor = '#727272'

export const disabledGreenColor = '#59cd904d'
export const greenColor = '#59cd90'
export const greenBackgroundColor = '#daf6e7'

export const blueColor = '#4b8cab'
export const blueBackgroundColor = '#dae0f6'
export const orangeColor = '#fd9644'

export const textColor = '#2c302e'
export const grey_1 = '#7f7f7f'
export const grey_2 = '#c9c9c9'
export const grey_3 = '#f4f4f4'

// Text sizes
export const textSizeTitle = 22
export const textSizeUndertitle = 24
export const textSizeCardtitle = 18
export const textSizeBodytitle = 17
export const textSizeBody = 14

export const textWeightHeavy = 800
export const textWeightBold = 'bold'
export const textWeightNormal = 400
export const textWeightThin = 300

// Spacing and heights
export const spacing = 8

export const maxWindowWidth = 1440
export const PLATFORM_NAVIGATION_BAR_HEIGHT = 56
export const toolbarHeight = 80
export const mobileToolbarHeight = 56
export const smartBannerHeight = 100
export const mobileGroupTabsHeight = 74
export const bottomBarHeight = 80
export const TABLE_BAR_HEIGHT = 70
export const mobileBottomBarHeight = 56
export const mobileHeaderWithGroupsHeight = mobileToolbarHeight + mobileGroupTabsHeight
export const drawerWidthExpanded = 200
export const drawerWidthCollapsed = 72
export const defaultButtonHeight = 50

// Borders and shadows
export const defaultBorder = `2px solid ${grey_3}`
export const defaultInnerDropShadow = 'inset 0px 0px 16px 0px rgba(0, 0, 0, 0.08)'
export const defaultBoxShadow = '0px 2px 16px 0px rgba(0, 0, 0, 0.08)'
export const heavyBoxShadow = '8px 8px 32px 0px rgba(0, 0, 0, 0.16)'
export const cardContentShadow = '0 0 15px 0 rgba(0, 0, 0, 0.05)'
export const defaultHoverState = {
    boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.08)',
}
export const defaultGrey2HoverState = {
    transition: 'background-color 0.2s ease-in-out',

    '&:hover': {
        backgroundColor: `${grey_2}66`,
    },
}

// Transitions
export const defaultTransitionDuration = 300
export const defaultTransition = `all ${defaultTransitionDuration / 1000}s ease-in-out`

// Scrollbar
export const hideScrollbar = {
    scrollbarWidth: 'none',
    '::WebkitScrollbar': {
        display: 'none',
    },
} as any

// Responsive
export const maxWidthMobile = 700
export const maxWidthPhablet = 850
export const maxWidthTablet = 1200
export const isMobileCss = `@media (max-width:${maxWidthMobile}px)`
export const isPhabletCss = `@media (max-width: ${maxWidthPhablet}px)`
export const isTabletCss = `@media (max-width: ${maxWidthTablet}px)`
