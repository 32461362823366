import { MinimalUserInfo } from '../../../../user/User'
import {
    AvatarProps,
    Badge as MuiBadge,
    Box,
    ListItemAvatar as MuiListItemAvatar,
    ListItemAvatarProps,
    styled,
    Typography,
} from '@mui/material'
import { defaultBoxShadow, grey_1, grey_2, textColor } from 'shared/lib/theme/Theme'
import React from 'react'
import { Avatar } from 'shared/lib/components/Avatar'
import { fileRepository } from '../../../../index'
import { ReactComponent as NotificationIcon } from '../../../../assets/svg/bell_muted.svg'
import { useTranslation } from 'shared/lib/i18n'
import { FilledCloseButtonWithWhiteCross } from 'shared/lib/components/buttons/CloseButton'

export const NonGroupMemberTaggedNotification = ({
    user,
    closeNotification,
}: {
    user: MinimalUserInfo

    closeNotification(): void
}) => {
    const translations = useTranslation()

    return (
        <Container>
            <Badge
                badgeContent={<NotificationIcon fill={textColor} />}
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                color="primary"
            >
                <UserAvatar src={fileRepository.getThumbnailUrl(user.image)} />
            </Badge>
            <Typography
                dangerouslySetInnerHTML={{
                    __html: translations('user_not_in_group_tag_notification', [
                        [user.firstName, user.lastName].join(' '),
                    ]).replace(
                        translations('wont_receive_a_notification'),
                        `<strong>${translations('wont_receive_a_notification')}</strong>`
                    ),
                }}
            />
            <FilledCloseButtonWithWhiteCross onClick={closeNotification} color={grey_1} />
        </Container>
    )
}

export const OnlyGroupMembersNotification = ({
    closeNotification,
}: {
    closeNotification(): void
}) => {
    const translations = useTranslation()

    return (
        <Container>
            <Avatar
                sx={{
                    backgroundColor: grey_2,
                }}
            >
                <NotificationIcon fill={textColor} />
            </Avatar>
            <Typography
                dangerouslySetInnerHTML={{
                    __html: translations('users_not_in_group_tag_notification').replace(
                        translations('group_members'),
                        `<strong>${translations('group_members')}</strong>`
                    ),
                }}
                width="100%"
            />
            <FilledCloseButtonWithWhiteCross onClick={closeNotification} color={grey_1} />
        </Container>
    )
}

const Container = styled(Box)(({ theme }) => ({
    borderRadius: theme.spacing(10),
    boxShadow: defaultBoxShadow,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'sticky',
    margin: theme.spacing(0, 3, 3),
    top: '1000px', // For some reason setting bottom does not work
    backgroundColor: 'white',
    textAlign: 'left',
}))

const Badge = styled(MuiBadge)(() => ({
    '& .MuiBadge-badge': {
        backgroundColor: grey_2,
        width: 26,
        height: 26,
        borderRadius: 12,
    },
}))

const UserAvatar = styled(
    ({ src, children, ...props }: ListItemAvatarProps & Pick<AvatarProps, 'src'>) => (
        <MuiListItemAvatar {...props}>{children || <Avatar src={src} />}</MuiListItemAvatar>
    )
)(() => ({
    minWidth: 40,
}))
