import { Box, Typography } from '@mui/material'
import { IOSSwitch } from './IOSSwitch'
import { grey_1 } from 'shared/lib/theme/Theme'

interface Properties {
    testId?: string
    header?: string
    title: string
    titleNote?: string
    description?: string
    disabledTooltipMessage?: string
    isChecked: boolean
    isDisabled?: boolean
    onChanged: (value: boolean) => void
}

export const SwitchRow = (properties: Properties) => {
    const { title, titleNote, description, header } = properties
    const isDisabled = Boolean(properties.isDisabled)
    return (
        <Box
            data-testid={properties.testId}
            key={title}
            pb={1}
            display="flex"
            flexDirection="row"
            alignItems="center"
        >
            <Box mr={6} flexGrow={1}>
                {header && (
                    <Box mb={2}>
                        <Typography variant="h3">{header}</Typography>
                    </Box>
                )}
                <Typography variant="body1">
                    {title}
                    {titleNote && <span style={{ color: grey_1 }}>{titleNote}</span>}
                </Typography>
                {description && (
                    <Box mt={0.5}>
                        <Typography variant="body2">{description}</Typography>
                    </Box>
                )}
            </Box>

            <Box>
                <IOSSwitch
                    checked={properties.isChecked}
                    disabled={isDisabled}
                    onChange={(event: any) => properties.onChanged(event.target.checked)}
                />
            </Box>
        </Box>
    )
}
