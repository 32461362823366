import { Box, styled } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ReactComponent as AlertIcon } from 'shared/lib/assets/svg/alert.svg'
import { AlertDialog } from 'shared/lib/components/AlertDialog'
import { ErrorHandler } from 'shared/lib/components/ErrorHandler'
import { ButtonType } from 'shared/lib/components/buttons/ButtonType'
import { errorColor } from 'shared/lib/theme/Theme'
import { DetailViewContainer } from '../../common/detailView'
import { DETAIL_VIEW_HEADER_BOTTOM_Y } from '../../common/detailView/DetailViewHeader'
import { useDetailView } from '../../common/detailView/hooks'
import { useTranslation } from 'shared/lib/i18n'
import { eventRepository } from '../../index'
import { EventActionsButton } from '../components/EventActionsButton'
import { EditEventPopup } from '../createOrEdit/EditEventPopup'
import { EventDeletedEvent, EventEventKey, EventUpdatedEvent } from '../events'
import { EventContentView } from './EventContentView'
import { EventLoadingView } from './EventLoadingView'
import { useEvent } from './hooks'

interface Properties {
    closeIcon: React.JSX.Element
    onCloseClicked(): void
}

export const EventDetailEvent = ({ closeIcon, onCloseClicked }: Properties) => {
    const translations = useTranslation()
    const [searchParams] = useSearchParams()

    const eventId = searchParams.get('eventId')!

    const { isDetailViewDisplayedAsPopup } = useDetailView()
    const { isLoading, error: eventError, event, setEvent } = useEvent(eventId)

    const [isEditPopupVisible, setIsEditPopupVisible] = useState(false)
    const [eventIdToDelete, setEventIdToDelete] = useState<string | undefined>()
    const [isDeletingEvent, setIsDeletingEvent] = useState(false)
    const [error, setError] = useState<Error | undefined>()

    const deleteEvent = () => {
        if (!eventIdToDelete) {
            return
        }

        setIsDeletingEvent(true)

        eventRepository
            .deleteEvent(eventIdToDelete)
            .then(onCloseClicked)
            .catch(setError)
            .finally(() => {
                setIsDeletingEvent(false)
                setEventIdToDelete(undefined)
            })
    }

    const onEventUpdatedEventReceived = useCallback(
        (event: Event) => {
            const updatedEvent = (event as EventUpdatedEvent).detail.event
            if (updatedEvent.id === eventId) {
                setEvent(updatedEvent)
            }
        },
        [eventId, setEvent]
    )

    const onEventDeletedEventReceived = useCallback(
        (event: Event) => {
            const deletedEventId = (event as EventDeletedEvent).detail.eventId
            if (deletedEventId === eventId) {
                onCloseClicked()
            }
        },
        [eventId, onCloseClicked]
    )

    useEffect(() => {
        document.addEventListener(EventEventKey.EVENT_UPDATED, onEventUpdatedEventReceived)
        document.addEventListener(EventEventKey.EVENT_DELETED, onEventDeletedEventReceived)

        return () => {
            document.removeEventListener(EventEventKey.EVENT_DELETED, onEventDeletedEventReceived)
            document.addEventListener(EventEventKey.EVENT_UPDATED, onEventUpdatedEventReceived)
        }
    }, [onEventUpdatedEventReceived, onEventDeletedEventReceived])

    return (
        <DetailViewContainer
            overflow="auto"
            closeIcon={closeIcon}
            onCloseClicked={onCloseClicked}
            actionButton={
                event?.canEdit ? (
                    <EventActionsButton
                        event={event}
                        onEditButtonClicked={() => setIsEditPopupVisible(true)}
                        onDeleteButtonClicked={() => setEventIdToDelete(eventId)}
                    />
                ) : undefined
            }
        >
            <Container>
                {isLoading ? (
                    <EventLoadingView />
                ) : event ? (
                    <EventContentView event={event} />
                ) : (
                    <></>
                )}

                {isEditPopupVisible && event && (
                    <EditEventPopup
                        event={event}
                        onCloseButtonClicked={() => setIsEditPopupVisible(false)}
                        onEventUpdated={() => setIsEditPopupVisible(false)}
                    />
                )}

                <AlertDialog
                    isLoading={isDeletingEvent}
                    isVisible={!!eventIdToDelete}
                    titleIcon={<AlertIcon fill={errorColor} />}
                    title={translations('are_you_sure')}
                    message={translations('delete_event_message')}
                    cancelButtonTitle={translations('cancel')}
                    onCancelButtonClicked={() => setEventIdToDelete(undefined)}
                    continueButtonType={ButtonType.RED}
                    continueButtonTitle={translations('delete_event')}
                    onContinueButtonClicked={deleteEvent}
                />

                <ErrorHandler
                    error={error || eventError}
                    translations={translations}
                    horizontal={isDetailViewDisplayedAsPopup ? 'center' : 'right'}
                />
            </Container>
        </DetailViewContainer>
    )
}

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'start',
    paddingTop: `${DETAIL_VIEW_HEADER_BOTTOM_Y}px`,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    textAlign: 'left',
}))
