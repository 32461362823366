import { useCallback, useEffect, useRef, useState } from 'react'
import { sessionRepository, userRepository } from '../index'
import { User } from './User'
import { UserApprovedEvent, UserDeniedEvent, UserEventKey } from './UserEvents'

export const useUser = (userId: string) => {
    const [isLoading, setIsLoading] = useState(true)
    const [currentUserId, setCurrentUserId] = useState(userId)
    const [user, setUser] = useState<User | undefined>(undefined)
    const [hasBalance, setHasBalance] = useState<boolean | undefined>(undefined)

    const getUser = useCallback(() => {
        setIsLoading(true)

        userRepository
            .getUser(userId)
            .then(setUser)
            .finally(() => setIsLoading(false))
    }, [userId])

    const getHasBalance = () => {
        setHasBalance(undefined)
        userRepository.hasBalance(userId).then((bool) => setHasBalance(bool))
    }

    useEffect(() => {
        setCurrentUserId(userId)
        getUser()
    }, [userId, getUser])

    return { isLoading, currentUserId, user, hasBalance, getUser, getHasBalance }
}

interface UseUserProperties {
    isApproved: boolean
    initialValue?: User[]
    loadUsersOnMount?: boolean
}

export const useUsers = (properties: UseUserProperties) => {
    const [isLoading, setIsLoading] = useState(false)
    const [users, setUsers] = useState<User[]>(properties.initialValue ?? [])
    const [error, setError] = useState<Error | undefined>()

    const abortControllerRef = useRef<AbortController>()

    const loadUsersOnMount = properties.loadUsersOnMount ?? true

    const getUsers = useCallback(() => {
        if (!properties.isApproved && !sessionRepository.canApproveOrDenyChurchMembers) {
            setUsers([])
            return
        }

        setIsLoading(true)

        userRepository
            .getUsers({ isApproved: properties.isApproved }, abortControllerRef.current?.signal)
            .then(setUsers)
            .catch((error) => setError(error))
            .finally(() => setIsLoading(false))
    }, [properties.isApproved])

    const onApproveUser = (userId: string) => {
        const originalUsers = users
        setUsers(originalUsers.filter((user) => user.id !== userId))

        userRepository
            .approveUser(userId)
            .then(() => {
                document.dispatchEvent(new UserApprovedEvent(userId))
            })
            .catch((error) => {
                setError(error)
                setUsers(originalUsers)
            })
    }

    const onDenyUser = (userId: string) => {
        const originalUsers = users
        setUsers(originalUsers.filter((user) => user.id !== userId))

        userRepository
            .denyUser(userId)
            .then(() => {
                document.dispatchEvent(new UserDeniedEvent(userId))
            })
            .catch((error) => {
                setError(error)
                setUsers(originalUsers)
            })
    }

    useEffect(() => {
        document.addEventListener(UserEventKey.USER_DELETED, getUsers)
        document.addEventListener(UserEventKey.USER_APPROVED, getUsers)

        setUsers(properties.initialValue ?? [])

        if (!properties.initialValue && loadUsersOnMount) {
            abortControllerRef.current?.abort()
            abortControllerRef.current = new AbortController()
            getUsers()
        }

        return () => {
            abortControllerRef.current?.abort()
            document.removeEventListener(UserEventKey.USER_DELETED, getUsers)
            document.removeEventListener(UserEventKey.USER_APPROVED, getUsers)
        }
    }, [properties.initialValue, loadUsersOnMount, getUsers])

    return { isLoading, users, getUsers, onApproveUser, onDenyUser, error }
}
