import {
    Box,
    List as MuiList,
    ListItem,
    ListItemIcon as MuiListItemIcon,
    ListItemText as MuiListItemText,
    styled,
} from '@mui/material'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { ReactComponent as AlertIcon } from 'shared/lib/assets/svg/alert.svg'
import { AlertDialog } from 'shared/lib/components/AlertDialog'
import { ErrorHandler } from 'shared/lib/components/ErrorHandler'
import { ButtonType } from 'shared/lib/components/buttons/ButtonType'
import { PrimaryContainedButton } from 'shared/lib/components/buttons/ContainedButtons'
import { GreyContainedButton } from 'shared/lib/components/buttons/GreyButtons'
import { errorColor, grey_3, paperColor } from 'shared/lib/theme/Theme'
import { ReactComponent as BirthdayIcon } from '../../assets/svg/birthday.svg'
import { ReactComponent as AddressIcon } from '../../assets/svg/location.svg'
import { ReactComponent as MailIcon } from '../../assets/svg/mail.svg'
import { ReactComponent as PhoneIcon } from '../../assets/svg/phone.svg'
import { ReactComponent as SmartPhoneIcon } from '../../assets/svg/smartphone.svg'
import sessionContextProvider from '../../authentication/SessionContextProvider'
import {
    DescriptionTextView,
    DetailViewContainer,
    StyledAvatar,
    TitleTextView,
} from '../../common/detailView'
import { DetailViewProperties } from '../../common/detailView/DetailViewProperties'
import { useTranslation } from 'shared/lib/i18n'
import { fileRepository } from '../../index'
import { UserApprovedEvent, UserEventKey } from '../UserEvents'
import { getInitials } from '../Utils'
import { getFullName } from 'shared/lib/utils/StringUtils'
import { useUser, useUsers } from '../hooks'
import { GroupsList } from './GroupsList'
import { FunctionsTextView } from './UserDetailComponents'
import { UserDetailLoadingState } from './UserDetailLoadingState'
import { UserActionsButton } from './UserDetailComponents/UserActionsButton'
import { DeleteAccountDialog } from '../settings/deleteaccount/DeleteAccountDialog'
import { FullScreenMediaContext } from 'shared/lib/components/media/FullScreenMediaContextProvider'
import { MediaItem } from 'shared/lib/models/MediaItem'

interface Properties extends DetailViewProperties {
    userId: string
    canOpenNewDetailView: boolean
    onGroupSelected(groupId: string): void
}

export const UserDetailView = ({ userId, ...properties }: Properties) => {
    const translations = useTranslation()
    const { openFullscreenMedia } = useContext(FullScreenMediaContext)!

    const { isLoading, currentUserId, user, hasBalance, getUser, getHasBalance } = useUser(userId)
    const { onApproveUser, onDenyUser, error } = useUsers({
        isApproved: false,
        loadUsersOnMount: false,
    })

    const signedInUser = useContext(sessionContextProvider)!.getSignedInUser()

    const [isDenyingUser, setIsDenyingUser] = useState<boolean>(false)
    const [isApproveButtonLoading, setIsApproveButtonLoading] = useState(false)
    const [isFullWidth, setIsFullWidth] = useState(true)
    const [userIdToDelete, setUserIdToDelete] = useState<string | undefined>(undefined)

    const showActionButton = signedInUser?.canEditOrDeleteUsers && signedInUser?.id !== userId

    const onProfileImageClick = () => {
        if (!user?.image) {
            return
        }
        openFullscreenMedia(0, MediaItem.mapToMediaItem(user.image, fileRepository))
    }

    const denyUser = () => {
        onDenyUser(userId)
        properties.onCloseClicked()
    }

    const onApproveUserClicked = () => {
        setIsFullWidth(false)
        waitForTransitionAndExecute(() => setIsApproveButtonLoading(true))
        onApproveUser(userId)
    }

    const waitForTransitionAndExecute = (fn: () => void, delay = 15) => {
        setTimeout(fn, delay)
    }

    const onUserApproved = useCallback(
        (event: Event) => {
            if ((event as UserApprovedEvent).detail.userId === userId) {
                getUser()
            }
        },
        [getUser, userId]
    )

    const onDeleteUserClicked = () => {
        setUserIdToDelete(undefined)
        document.dispatchEvent(new CustomEvent(UserEventKey.USER_DELETED))
        properties.onCloseClicked()
    }

    useEffect(() => {
        document.addEventListener(UserEventKey.USER_APPROVED, onUserApproved)

        return () => {
            document.removeEventListener(UserEventKey.USER_APPROVED, onUserApproved)
        }
    }, [onUserApproved])

    if (isLoading || currentUserId !== userId) {
        return (
            <DetailViewContainer
                onCloseClicked={properties.onCloseClicked}
                closeIcon={properties.closeIcon}
            >
                <Container>
                    <UserDetailLoadingState />
                </Container>
            </DetailViewContainer>
        )
    }

    if (!user) {
        properties.onCloseClicked()

        return <></>
    }

    const dataRows = [
        {
            icon: <MailIcon />,
            value:
                user.emailAddress && !user.isEmailAddressHidden ? (
                    <a href={`mailto:${user.emailAddress}`}>{user.emailAddress}</a>
                ) : undefined,
        },
        {
            icon: <SmartPhoneIcon />,
            value: user.mobilePhoneNumber ? (
                <a href={`tel:${user.mobilePhoneNumber}`}>{user.mobilePhoneNumber}</a>
            ) : undefined,
        },
        {
            icon: <PhoneIcon />,
            value: user.phoneNumber ? (
                <a href={`tel:${user.phoneNumber}`}>{user.phoneNumber}</a>
            ) : undefined,
        },
        {
            icon: <AddressIcon />,
            value: Object.values(user.address || {}).join(', '),
        },
        {
            icon: <BirthdayIcon />,
            value: user.birthday?.toLocaleDateString(),
        },
    ].filter((row) => row.value)

    return (
        <DetailViewContainer
            onCloseClicked={properties.onCloseClicked}
            closeIcon={properties.closeIcon}
            overflow="auto"
            actionButton={
                showActionButton ? (
                    <UserActionsButton
                        onDeleteButtonClicked={() => {
                            setUserIdToDelete(userId)
                            getHasBalance()
                        }}
                    />
                ) : undefined
            }
        >
            <Container>
                <StyledAvatar
                    src={fileRepository.getImageUrl(user.image)}
                    onClick={onProfileImageClick}
                    sx={{ cursor: user.image ? 'pointer' : 'default' }}
                >
                    {getInitials(user)}
                </StyledAvatar>

                <TitleTextView>{getFullName(user)}</TitleTextView>

                {user.functions && (
                    <FunctionsTextView>{user.functions.join(', ')}</FunctionsTextView>
                )}

                {user.about && <DescriptionTextView>{user.about}</DescriptionTextView>}

                {signedInUser!.canApproveOrDenyChurchMembers && !user.isApproved && (
                    <ApproveOrDenyAccessButtonGroup>
                        <GreyContainedButton
                            fullWidth={isFullWidth}
                            onClick={() => setIsDenyingUser(true)}
                            isLoading={false}
                            isVisible={!isApproveButtonLoading}
                        >
                            {translations('deny')}
                        </GreyContainedButton>
                        <PrimaryContainedButton
                            fullWidth={isFullWidth}
                            onClick={onApproveUserClicked}
                            isLoading={isApproveButtonLoading}
                            isVisible={true}
                        >
                            {translations('approve')}
                        </PrimaryContainedButton>
                    </ApproveOrDenyAccessButtonGroup>
                )}

                <List>
                    {dataRows.map(({ icon, value }, index) => (
                        <DetailRow key={index}>
                            <ListItemIcon>{icon}</ListItemIcon>
                            <ListItemText primary={value} />
                        </DetailRow>
                    ))}
                </List>

                <GroupsList
                    userId={userId}
                    canOpenNewDetailView={properties.canOpenNewDetailView}
                    onGroupSelected={properties.onGroupSelected}
                />
            </Container>

            <AlertDialog
                titleIcon={<AlertIcon fill={errorColor} />}
                isVisible={isDenyingUser}
                title={translations('are_you_sure')}
                message={translations('confirm_deny_user_message', [getFullName(user)])}
                continueButtonType={ButtonType.RED}
                continueButtonTitle={translations('deny')}
                cancelButtonTitle={translations('cancel')}
                onContinueButtonClicked={denyUser}
                onCancelButtonClicked={() => setIsDenyingUser(false)}
            />

            <DeleteAccountDialog
                isVisible={!!userIdToDelete}
                onCancel={() => setUserIdToDelete(undefined)}
                onContinueButtonClicked={onDeleteUserClicked}
                hasBalance={hasBalance}
                userToDelete={user}
            />

            <ErrorHandler error={error} translations={translations} horizontal="right" />
        </DetailViewContainer>
    )
}

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    padding: theme.spacing(4, 3, 2),
}))

const ApproveOrDenyAccessButtonGroup = styled(Box)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    marginTop: theme.spacing(2),
    gap: theme.spacing(2),
    justifyContent: 'center',
}))

const List = styled(MuiList)(({ theme }) => ({
    width: '100%',
    marginTop: theme.spacing(2),
}))

const DetailRow = styled(ListItem)(({ theme }) => ({
    backgroundColor: grey_3,
    borderBottom: `1px solid ${paperColor}`,

    ':first-of-type': {
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
    },

    ':last-of-type': {
        borderBottomLeftRadius: 16,
        borderBottomRightRadius: 16,
    },

    '& a': {
        color: 'inherit',
        textDecoration: 'none',
    },

    '& svg': {
        fill: theme.palette.primary.main,
    },
}))

const ListItemIcon = styled(MuiListItemIcon)(({ theme }) => ({
    minWidth: 20,
    minHeight: 20,
    marginRight: theme.spacing(2),
}))

const ListItemText = styled(MuiListItemText)(() => ({
    overflowWrap: 'anywhere',
}))
