import { User } from './User'

export enum UserEventKey {
    USER_APPROVED = 'USER_APPROVED',
    USER_DENIED = 'USER_DENIED',
    USER_DELETED = 'USER_DELETED',
}

export class UserApprovedEvent extends CustomEvent<{ userId: User['id'] }> {
    constructor(userId: string) {
        super(UserEventKey.USER_APPROVED, { detail: { userId } })
    }
}

export class UserDeniedEvent extends CustomEvent<{ userId: User['id'] }> {
    constructor(userId: string) {
        super(UserEventKey.USER_DENIED, { detail: { userId } })
    }
}
