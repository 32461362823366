import { GroupWithAccessRequests } from '../../groups/GroupWithAccessRequests'
import React, { useEffect, useMemo, useState } from 'react'
import { List as MuiList, ListSubheader as MuiListSubheader, styled } from '@mui/material'
import { darkGrey } from 'shared/lib/theme/Theme'
import { UnapprovedUserListItem } from '../../common/lists/UnapprovedUserListItem'
import { MinimalUserInfo } from '../../user/User'
import { SeeAllButton } from '../../common/lists/SeeAllButton'
import { AlertDialog } from 'shared/lib/components/AlertDialog'
import { useTranslation } from 'shared/lib/i18n'
import { ButtonType } from 'shared/lib/components/buttons/ButtonType'
import { Group } from '../../groups/Group'
import { useDetailView } from '../../common/detailView/hooks'
import {
    GroupWithAccessRequestsHeaderLoadingViewModel,
    GroupWithAccessRequestsHeaderViewModel,
    GroupWithAccessRequestsItemLoadingViewModel,
    GroupWithAccessRequestsItemViewModel,
    GroupWithAccessRequestsViewModel,
} from './GroupWithAccessRequestsListViewModels'
import { Skeleton } from 'shared/lib/components/Skeleton'
import { UserListLoadingRow } from '../../user/userList/UserListItemRows'

interface Properties {
    availableHeight?: number
    groupAccessRequests: GroupWithAccessRequests[]
    isLoading: boolean

    onApproveGroupMember(groupId: string, userId: string): void
    onDenyGroupMember(groupMemberToDeny: {
        groupMember: MinimalUserInfo
        groupId: Group['id']
    }): void
}

const GROUP_NAME_HEIGHT = 50
const ACCESS_REQUEST_HEIGHT = 65
export const SEE_MORE_BUTTON_HEIGHT = 45
const MINIMUM_HEIGHT = GROUP_NAME_HEIGHT + ACCESS_REQUEST_HEIGHT

export const GroupsWithAccessRequestsList = ({ availableHeight, ...properties }: Properties) => {
    const translations = useTranslation()

    const { openGroupAccessRequests } = useDetailView()

    const [groupAccessRequests, setGroupAccessRequests] = useState(properties.groupAccessRequests)
    const [groupMemberToDeny, setGroupMemberToDeny] = useState<
        { groupMember: MinimalUserInfo; groupId: Group['id'] } | undefined
    >()

    const totalRequests = groupAccessRequests.reduce(
        (total, { members }) => total + members.length,
        0
    )

    const { viewModels, isShowingAll } = useMemo(
        () => getRowsThatFitScreen(groupAccessRequests, properties.isLoading, availableHeight),
        [groupAccessRequests, availableHeight, properties.isLoading]
    )

    const onDenyGroupMember = setGroupMemberToDeny

    const onDenyGroupMemberClicked = () => {
        if (!groupMemberToDeny) {
            return
        }

        properties.onDenyGroupMember(groupMemberToDeny)
        setGroupMemberToDeny(undefined)
    }

    useEffect(() => {
        setGroupAccessRequests(properties.groupAccessRequests)
    }, [properties.groupAccessRequests])

    return (
        <>
            <List>
                {viewModels.map((viewModel, index) => {
                    if (viewModel instanceof GroupWithAccessRequestsHeaderViewModel) {
                        const { group } = viewModel

                        return <ListSubheader key={group.id}>{group.name}</ListSubheader>
                    } else if (viewModel instanceof GroupWithAccessRequestsHeaderLoadingViewModel) {
                        return <Skeleton key="loading-header" width="95%" height={30} />
                    } else if (viewModel instanceof GroupWithAccessRequestsItemLoadingViewModel) {
                        return <UserListLoadingRow key={index} />
                    } else if (viewModel instanceof GroupWithAccessRequestsItemViewModel) {
                        const { member, group } = viewModel
                        return (
                            <UnapprovedUserListItem
                                key={group.id + member.id}
                                userToApprove={member as any}
                                propertyToUseForSecondaryLabel="functions"
                                onApprove={() =>
                                    properties.onApproveGroupMember(group.id, member.id)
                                }
                                onDeny={() =>
                                    onDenyGroupMember({
                                        groupMember: member,
                                        groupId: group.id,
                                    })
                                }
                            />
                        )
                    } else {
                        return null
                    }
                })}
            </List>

            {availableHeight && !isShowingAll && (
                <SeeAllButton
                    sx={{
                        marginLeft: -1,
                    }}
                    translationKey={'show_all_group_access_requests'}
                    totalMembersCount={totalRequests}
                    onClick={() => openGroupAccessRequests({ groupId: 'all' })}
                />
            )}

            <AlertDialog
                isVisible={!!groupMemberToDeny}
                title={translations('are_you_sure')}
                message={translations('user_requests_access_deny_confirmation', [
                    groupMemberToDeny?.groupMember.firstName || '',
                    groupMemberToDeny?.groupMember.lastName || '',
                ])}
                continueButtonType={ButtonType.RED}
                continueButtonTitle={translations('deny')}
                cancelButtonTitle={translations('cancel')}
                onContinueButtonClicked={onDenyGroupMemberClicked}
                onCancelButtonClicked={() => setGroupMemberToDeny(undefined)}
            />
        </>
    )
}

const getRowsThatFitScreen = (
    groupAccessRequests: GroupWithAccessRequests[],
    isLoading: boolean,
    availableHeight?: number
) => {
    if (isLoading) {
        return {
            viewModels: [
                new GroupWithAccessRequestsHeaderLoadingViewModel(),
                ...Array.from(
                    { length: 5 },
                    () => new GroupWithAccessRequestsItemLoadingViewModel()
                ),
            ],
            isShowingAll: true,
        }
    }

    return groupAccessRequests.reduce(
        (acc, { members, ...group }, index) => {
            let { remainingHeight = 100000, viewModels } = acc
            const isLastGroup = index === groupAccessRequests.length - 1
            const membersToShow: MinimalUserInfo[] = []

            if (remainingHeight <= MINIMUM_HEIGHT) {
                return acc
            }

            remainingHeight -= GROUP_NAME_HEIGHT

            members.forEach((member) => {
                if (remainingHeight <= ACCESS_REQUEST_HEIGHT) {
                    return
                }

                remainingHeight -= ACCESS_REQUEST_HEIGHT
                membersToShow.push(member)
            })

            if (!membersToShow.length) {
                return acc
            }

            if (remainingHeight <= MINIMUM_HEIGHT) {
                // Last member is replaced by the "See all" button
                membersToShow.pop()
            }

            if (!membersToShow.length) {
                return acc
            }

            viewModels.push(
                new GroupWithAccessRequestsHeaderViewModel(group),
                ...membersToShow.map(
                    (member) => new GroupWithAccessRequestsItemViewModel(member, group)
                )
            )

            return {
                remainingHeight,
                isShowingAll: isLastGroup && membersToShow.length === members.length,
                viewModels,
            }
        },
        {
            viewModels: [] as GroupWithAccessRequestsViewModel[],
            remainingHeight: availableHeight,
            isShowingAll: false,
        }
    )
}

const List = styled(MuiList)(({ theme }) => ({
    padding: 0,
    paddingBottom: theme.spacing(1),
}))

const ListSubheader = styled(MuiListSubheader)(({ theme }) => ({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: darkGrey,
    fontSize: 14,
    fontWeight: 700,
    padding: 0,
    lineHeight: 'unset',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
}))
