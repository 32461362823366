import { AlertDialog } from 'shared/lib/components/AlertDialog'
import { ButtonType } from 'shared/lib/components/buttons/ButtonType'
import { errorColor, grey_3 } from 'shared/lib/theme/Theme'
import { ReactComponent as Alert } from 'shared/lib/assets/svg/alert.svg'
import { useContext, useState } from 'react'
import { Box, CircularProgress, FormControlLabel, styled, Typography } from '@mui/material'
import { Checkbox } from 'shared/lib/components/Checkbox'
import ChurchContext from '../../../churches/ChurchContextProvider'
import { sessionRepository, userRepository } from '../../../index'
import { ErrorHandler } from 'shared/lib/components/ErrorHandler'
import { useTranslation } from 'shared/lib/i18n'
import { User } from '../../User'

interface DeleteAccountPopupProperties {
    isVisible: boolean
    hasBalance: boolean | undefined
    onCancel: () => void
    onContinueButtonClicked?: () => void
    userToDelete?: User
}

export const DeleteAccountDialog = (properties: DeleteAccountPopupProperties) => {
    const translations = useTranslation()
    const church = useContext(ChurchContext)!.church

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isChecked, setIsChecked] = useState<boolean>(false)
    const [error, setError] = useState<Error | undefined>()

    const canDelete = properties.hasBalance === false || isChecked
    const canContinueWhenDeletingOtherUser = properties.userToDelete && canDelete
    const canContinueWhenDeletingOwnAccount = !properties.userToDelete && canDelete

    const canContinue = canContinueWhenDeletingOtherUser || canContinueWhenDeletingOwnAccount

    const onContinueButtonClicked = () => {
        setIsLoading(true)

        if (properties.userToDelete) {
            userRepository
                .deleteUser(properties.userToDelete.id)
                .catch(setError)
                .finally(() => {
                    properties.onContinueButtonClicked?.()
                    setIsLoading(false)
                })
            return
        }

        sessionRepository
            .deleteAccount()
            .catch(setError)
            .finally(() => setIsLoading(false))
    }

    return (
        <>
            <AlertDialog
                isVisible={properties.isVisible}
                isLoading={isLoading}
                isContinueDisabled={!canContinue}
                continueButtonType={ButtonType.RED}
                cancelButtonType={ButtonType.RED}
                titleIcon={<Alert fill={errorColor} />}
                title={translations('are_you_sure')}
                continueButtonTitle={translations('delete')}
                cancelButtonTitle={translations('cancel')}
                onContinueButtonClicked={onContinueButtonClicked}
                onCancelButtonClicked={properties.onCancel}
                message={
                    <Box>
                        {properties.hasBalance === undefined ? (
                            <Box display={'flex'} justifyContent={'center'}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <>
                                {!properties.userToDelete && (
                                    <Typography>{translations('delete_account_info')}</Typography>
                                )}

                                {properties.hasBalance ? (
                                    <Box>
                                        <MessageContainer>
                                            <Typography>
                                                <WarningMessage>
                                                    {translations('warning')}:&nbsp;
                                                </WarningMessage>
                                                {properties.userToDelete
                                                    ? translations('open_balance_other_user', [
                                                          properties.userToDelete.firstName,
                                                      ])
                                                    : translations('open_balance_myself')}
                                            </Typography>
                                        </MessageContainer>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isChecked}
                                                    onChange={(event) => {
                                                        setIsChecked(event.target.checked)
                                                    }}
                                                />
                                            }
                                            label={translations(
                                                'delete_account_open_balance_free_to_spend',
                                                [church?.wordUsedForChurch(translations) || '']
                                            )}
                                        />
                                    </Box>
                                ) : (
                                    <>
                                        <Typography>
                                            {properties.userToDelete &&
                                                translations('confirm_delete_user', [
                                                    properties.userToDelete?.firstName,
                                                ])}
                                        </Typography>
                                    </>
                                )}
                            </>
                        )}
                    </Box>
                }
            />
            <ErrorHandler error={error} horizontal="center" translations={translations} />
        </>
    )
}

const MessageContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(3),
    whiteSpace: 'pre-wrap',
    background: grey_3,
    borderRadius: '24px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
}))

const WarningMessage = styled('span')(() => ({
    color: errorColor,
    fontWeight: 'bold',
}))
