import * as pdfLib from 'pdfjs-dist'
import pdfWorker from 'pdfjs-dist/build/pdf.worker.entry'
import { fromDataURI } from './BlobFactory'

export const generatePdfThumbnail = (file: File): Promise<Blob> => {
    return new Promise((resolve) => {
        const fileReader = new FileReader()

        pdfLib.GlobalWorkerOptions.workerSrc = pdfWorker

        fileReader.onload = async (event) => {
            // @ts-ignore
            const pdfData = new Uint8Array(event.target.result)
            const pdf = await pdfLib.getDocument({ data: pdfData }).promise

            // Fetch the first page
            const pageNumber = 1
            const page = await pdf.getPage(pageNumber)

            const scale = 1.5
            const viewport = page.getViewport({ scale: scale })

            // Prepare canvas using PDF page dimensions
            const canvas = document.createElement('canvas')
            const context = canvas.getContext('2d')
            canvas.height = viewport.height
            canvas.width = viewport.width

            // Render PDF page into canvas context
            await page.render({
                canvasContext: context!,
                viewport: viewport,
            }).promise

            resolve(fromDataURI(canvas.toDataURL('image/png')))
        }

        fileReader.readAsArrayBuffer(file)
    })
}
