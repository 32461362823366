import { Address } from 'shared/lib/models/Address'
import { Image } from 'shared/lib/models/Image'
import { DateTime } from 'luxon'
import { MinimalUserInfo } from '../../user/User'

export class SignedInUser implements MinimalUserInfo {
    constructor(
        readonly id: string,
        readonly emailAddress: string,
        readonly firstName: string,
        readonly lastName: string,
        readonly phoneNumber: string | undefined,
        readonly mobilePhoneNumber: string | undefined,
        readonly address: Address | undefined,
        readonly birthday: DateTime | undefined,
        readonly functions: string[],
        readonly about: string | undefined,
        readonly image: Image | undefined,
        readonly isEmailAddressHidden: boolean,
        readonly canSeeGroups: boolean,
        readonly canSeeChurchMembers: boolean,
        readonly canSeeNotificationSettings: boolean,
        readonly canApproveOrDenyChurchMembers: boolean,
        readonly canCreateOrUpdateCauses: boolean,
        readonly canCreateEventsForHomeGroup: boolean,
        readonly canCreateOrEditRestrictedGroups: boolean,
        readonly canCreateOrEditStartGroups: boolean,
        readonly canEditOrDeleteUsers: boolean,
        readonly canDeleteAccount: boolean,
        readonly canReportUGC: boolean,
        readonly canSeeMFASettings: boolean,
        readonly isMFAEnabled: boolean,
        readonly mfaPhoneNumber: string | undefined
    ) {}

    get isProfileCreated(): boolean {
        return (
            this.firstName !== undefined && this.lastName !== undefined && this.image !== undefined
        )
    }

    static fromJSON(response: Record<string, any>): SignedInUser {
        return new SignedInUser(
            response.id ?? response._id,
            response.emailAddress,
            response.firstName,
            response.lastName,
            response.phoneNumber,
            response.mobilePhoneNumber,
            response.address,
            response.birthday ? DateTime.fromISO(response.birthday) : undefined,
            response.functions,
            response.about,
            response.image,
            response.isEmailAddressHidden,
            response.canSeeGroups,
            response.canSeeChurchMembers,
            response.canSeeNotificationSettings,
            response.canApproveOrDenyChurchMembers,
            response.canCreateOrUpdateCauses,
            response.canCreateEventsForHomeGroup,
            response.canCreateOrEditRestrictedGroups,
            response.canCreateOrEditStartGroups,
            response.canEditOrDeleteUsers,
            response.canDeleteAccount,
            response.canReportUGC,
            response.canSeeMFASettings,
            response.isMFAEnabled,
            response.mfaPhoneNumber
        )
    }
}
