import { useState } from 'react'
import { ErrorHandler } from 'shared/lib/components/ErrorHandler'
import { LocalImage } from 'shared/lib/forms/LocalImage'
import { WizardPopup, WizardPopupFooterProperties } from 'shared/lib/wizard/WizardPopup'
import { useTranslation } from 'shared/lib/i18n'
import { groupRepository, sessionRepository } from '../../index'
import { Group } from '../Group'
import { AdminSettingsStepView } from './AdminSettingsStepView'
import { CreateOrEditGroupStep } from './CreateOrEditGroupStep'
import { GroupInfoStepView } from './GroupInfoStepView'
import { useDetailView } from '../../common/detailView/hooks'

interface Properties {
    group: Group
    onCloseClicked(): void
}

export const EditGroupPopup = ({ group, onCloseClicked }: Properties) => {
    const translations = useTranslation()

    const { isDetailViewDisplayedAsPopup } = useDetailView()

    const [groupName, setGroupName] = useState<string>(group.name)
    const [groupDescription, setGroupDescription] = useState<string>(group.description ?? '')
    const [groupImageFiles, setGroupImageFiles] = useState<
        | {
              imageFile: LocalImage
              thumbnailFile: LocalImage
          }
        | undefined
    >()
    const [isStartGroup, setIsStartGroup] = useState<boolean>(group.isStartGroup)
    const [isGroupRestricted, setIsGroupRestricted] = useState<boolean>(group.isRestricted)
    const [isSaving, setIsSaving] = useState(false)

    const [step, setStep] = useState<
        Exclude<CreateOrEditGroupStep, CreateOrEditGroupStep.CHOOSE_MEMBERS>
    >(CreateOrEditGroupStep.CHOOSE_IMAGE_NAME_DESCRIPTION)
    const [error, setError] = useState<string | undefined>(undefined)

    const signedInUser = sessionRepository.signedInUser
    const userHasAdminRights =
        (signedInUser?.canCreateOrEditRestrictedGroups ||
            signedInUser?.canCreateOrEditStartGroups) === true

    const hasChangesChooseImageNameDescriptionStep =
        groupName !== group.name ||
        groupDescription !== group.description ||
        groupImageFiles !== undefined
    const hasChangesChooseAdminSettingsStep =
        isStartGroup !== group.isStartGroup || isGroupRestricted !== group.isRestricted

    const canContinueChooseImageNameDescriptionStep =
        groupName.trim().length > 0 &&
        (hasChangesChooseImageNameDescriptionStep || userHasAdminRights)
    const canContinueChooseAdminSettingsStep =
        hasChangesChooseAdminSettingsStep || hasChangesChooseImageNameDescriptionStep

    const getTitle = () => {
        switch (step) {
            case CreateOrEditGroupStep.CHOOSE_IMAGE_NAME_DESCRIPTION:
                return translations('edit_group')
            case CreateOrEditGroupStep.CHOOSE_ADMIN_SETTINGS:
                return translations('admin_settings')
        }
    }

    const getFooter = (): WizardPopupFooterProperties => {
        switch (step) {
            case CreateOrEditGroupStep.CHOOSE_IMAGE_NAME_DESCRIPTION:
                return {
                    hasBackButton: false,
                    canContinue: canContinueChooseImageNameDescriptionStep,
                    nextButtonTitle:
                        signedInUser?.canCreateOrEditRestrictedGroups ||
                        signedInUser?.canCreateOrEditStartGroups
                            ? translations('next')
                            : translations('save'),
                    onNextButtonClicked,
                }
            case CreateOrEditGroupStep.CHOOSE_ADMIN_SETTINGS:
                return {
                    hasBackButton: !isSaving,
                    backButtonTitle: translations('back'),
                    onBackButtonClicked,
                    canContinue: canContinueChooseAdminSettingsStep,
                    nextButtonTitle: translations('save'),
                    onNextButtonClicked,
                }
        }
    }

    const onImageSelected = (params: { imageFile: LocalImage; thumbnailFile: LocalImage }) => {
        setGroupImageFiles(params)
    }

    const onNextButtonClicked = async () => {
        switch (step) {
            case CreateOrEditGroupStep.CHOOSE_IMAGE_NAME_DESCRIPTION:
                userHasAdminRights ? setStep(CreateOrEditGroupStep.CHOOSE_ADMIN_SETTINGS) : submit()
                break
            case CreateOrEditGroupStep.CHOOSE_ADMIN_SETTINGS:
                submit()
                return
        }
    }

    const onBackButtonClicked = () => {
        setStep(CreateOrEditGroupStep.CHOOSE_IMAGE_NAME_DESCRIPTION)
    }

    const submit = () => {
        setIsSaving(true)

        groupRepository
            .updateGroup(group.id, {
                name: groupName,
                description: groupDescription,
                fullSizeImage: groupImageFiles?.imageFile.file,
                thumbnailImage: groupImageFiles?.thumbnailFile.file,
                isStart: isStartGroup,
                isRestricted: isGroupRestricted,
            })
            .then(() => onCloseClicked())
            .catch(setError)
            .finally(() => setIsSaving(false))
    }

    return (
        <>
            <WizardPopup
                isVisible={true}
                isLoading={isSaving}
                withoutBackdrop={isDetailViewDisplayedAsPopup}
                title={getTitle()}
                footer={getFooter()}
                onCloseButtonClicked={onCloseClicked}
            >
                {step === CreateOrEditGroupStep.CHOOSE_IMAGE_NAME_DESCRIPTION && (
                    <GroupInfoStepView
                        groupName={groupName}
                        groupDescription={groupDescription}
                        groupImage={group.image}
                        groupImageFiles={groupImageFiles}
                        setGroupName={setGroupName}
                        setGroupDescription={setGroupDescription}
                        onImageSelected={onImageSelected}
                    />
                )}
                {step === CreateOrEditGroupStep.CHOOSE_ADMIN_SETTINGS && (
                    <AdminSettingsStepView
                        isStartGroup={isStartGroup}
                        isGroupRestricted={isGroupRestricted}
                        onIsStartGroupChanged={setIsStartGroup}
                        setIsGroupRestricted={setIsGroupRestricted}
                    />
                )}
            </WizardPopup>
            <ErrorHandler error={error} translations={translations} />
        </>
    )
}
