import { Box, BoxProps } from '@mui/material'
import { grey_3 } from '../theme/Theme'

export const HorizontalDivider = (props: BoxProps) => (
    <Box sx={{ height: '2px', background: grey_3 }} {...props} />
)

export const VerticalDivider = (props: BoxProps) => (
    <Box
        sx={{
            height: '100%',
            width: '2px',
            backgroundColor: grey_3,
        }}
        {...props}
    />
)
