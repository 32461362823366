import { useState } from 'react'
import { ErrorHandler } from 'shared/lib/components/ErrorHandler'
import { WizardPopup } from 'shared/lib/wizard/WizardPopup'
import { useDetailView } from '../common/detailView/hooks'
import { useTranslation } from 'shared/lib/i18n'
import { groupRepository } from '../index'
import { User } from '../user/User'
import {
    SearchUsers,
    SelectMembersStepView,
} from './createOrEditGroupPopup/selectMemberStep/SelectMembersStepView'
import { useGroupMembers } from './hooks'

interface Properties {
    groupId: string
    onCloseClicked(): void
}

export const AddMembersPopup = ({ groupId, onCloseClicked }: Properties) => {
    const translations = useTranslation()

    const { isDetailViewDisplayedAsPopup } = useDetailView()
    const { groupMembers } = useGroupMembers(groupId, { isApproved: true })

    const [isSaving, setIsSaving] = useState(false)
    const [query, setQuery] = useState('')
    const [error, setError] = useState<string | undefined>()
    const [selectedUsers, setSelectedUsers] = useState<User[]>([])

    const onUserSelected = (user: User) => {
        if (selectedUsers.find((u) => u.id === user.id)) {
            setSelectedUsers([...selectedUsers.filter((u) => u.id !== user.id)])
            return
        }
        setSelectedUsers([...selectedUsers, user])
    }

    const onNextButtonClicked = () => {
        setIsSaving(true)

        groupRepository
            .addGroupMembers(
                groupId,
                selectedUsers.map((user) => user.id)
            )
            .then(onCloseClicked)
            .catch(setError)
            .finally(() => setIsSaving(false))
    }
    return (
        <>
            <WizardPopup
                isVisible={true}
                isLoading={isSaving}
                withoutBackdrop={isDetailViewDisplayedAsPopup}
                title={translations('add_members')}
                headerContent={
                    <SearchUsers
                        onUserSelected={onUserSelected}
                        selectedUsers={selectedUsers}
                        query={query}
                        setQuery={setQuery}
                    />
                }
                footer={{
                    hasBackButton: false,
                    canContinue: !isSaving && selectedUsers.length > 0,
                    nextButtonTitle: translations('add_members'),
                    onNextButtonClicked,
                }}
                onCloseButtonClicked={onCloseClicked}
            >
                <SelectMembersStepView
                    onUserSelected={onUserSelected}
                    excludedUserIds={groupMembers.map((groupMember) => groupMember.id)}
                    selectedUsers={selectedUsers}
                    query={query}
                    setQuery={setQuery}
                />
            </WizardPopup>

            <ErrorHandler error={error} translations={translations} />
        </>
    )
}
