import { useCallback, useEffect, useRef, useState } from 'react'
import { delay } from 'shared/lib/utils/Utils'
import { commentRepository } from '..'
import { Comment } from './Comment'
import { CommentCreatedEvent, CommentEventKey, CommentUpdatedEvent } from './events'
import { DEFAULT_ANIMATION_DURATION } from 'shared/lib/common/Constants'

export const useComments = (postId: string) => {
    const isLoadingRef = useRef(false)

    const [comments, setComments] = useState<Comment[]>([])
    const [isUpdatingOrDeleting, setIsUpdatingOrDeleting] = useState(false)
    const [commentToEdit, setCommentToEdit] = useState<Comment | undefined>(undefined)
    const [commentIdToDelete, setCommentToDelete] = useState<string | undefined>(undefined)
    const [indexToScrollTo, setIndexToScrollTo] = useState<number | undefined>(undefined)
    const [error, setError] = useState<Error | undefined>(undefined)

    const getComments = useCallback(() => {
        if (isLoadingRef.current) {
            return
        }

        isLoadingRef.current = true

        Promise.all([commentRepository.getComments(postId), delay(DEFAULT_ANIMATION_DURATION)])
            .then(([comments]) => setComments(comments))
            .finally(() => (isLoadingRef.current = false))
    }, [postId])

    const onCommentCreated = useCallback((event: Event) => {
        const comment = (event as CommentCreatedEvent).detail.comment
        setComments((previousValue) => [comment, ...previousValue])
    }, [])

    const onCommentUpdated = useCallback((event: Event) => {
        const comment = (event as CommentUpdatedEvent).detail.comment
        setComments((previousValue) => [
            ...previousValue.map((previousComment) => {
                return previousComment.id === comment.id ? comment : previousComment
            }),
        ])
        setCommentToEdit(undefined)
    }, [])

    const deleteComment = (commentId: string) => {
        setIsUpdatingOrDeleting(true)

        commentRepository
            .deleteComment(postId, commentId)
            .then(() => {
                setComments((comments) => comments.filter((comment) => comment.id !== commentId))
                setIsUpdatingOrDeleting(false)
            })
            .catch((error) => {
                setError(error)
                setIsUpdatingOrDeleting(false)
            })
            .finally(() => {
                setIsUpdatingOrDeleting(false)
                setCommentToEdit(undefined)
                setCommentToDelete(undefined)
            })
    }

    useEffect(() => {
        document.addEventListener(CommentEventKey.COMMENT_CREATED, onCommentCreated)
        document.addEventListener(CommentEventKey.COMMENT_UPDATED, onCommentUpdated)

        return () => {
            document.removeEventListener(CommentEventKey.COMMENT_CREATED, onCommentCreated)
            document.removeEventListener(CommentEventKey.COMMENT_UPDATED, onCommentUpdated)
        }
    }, [onCommentCreated, onCommentUpdated])

    return {
        isLoading: isLoadingRef.current,
        isUpdatingOrDeleting,
        comments,
        commentToEdit,
        commentIdToDelete,
        indexToScrollTo,
        error,
        getComments,
        setCommentToEdit,
        setCommentToDelete,
        deleteComment,
        setIndexToScrollTo,
    }
}
