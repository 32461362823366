import { Image } from 'shared/lib/models/Image'
import { Address } from 'shared/lib/models/Address'
import { Cloneable } from '../../utils/Misc'
import { MinimalUserInfo } from '../../user/User'
import { SignedInUser } from '../../authentication/models/SignedInUser'
import { DateTime } from 'luxon'

export interface GroupMemberInfo extends MinimalUserInfo {
    emailAddress: string
    isApproved?: boolean
    isAdmin?: boolean
}

export class GroupMember
    extends Cloneable<GroupMember>
    implements GroupMemberInfo, MinimalUserInfo
{
    constructor(
        public readonly id: string,
        public readonly firstName: string,
        public readonly lastName: string,
        public readonly image: Image | undefined,
        public readonly birthday: DateTime | undefined,
        public readonly hasProfile: boolean,
        public readonly functions: string[],
        public readonly about: string,
        public readonly emailAddress: string,
        public readonly mobilePhoneNumber: string,
        public readonly phoneNumber: string,
        public readonly address: Address | undefined,
        public readonly isAdmin: boolean,
        public readonly isApproved: boolean
    ) {
        super()
    }

    static fromSignedInUser(user: SignedInUser): GroupMember {
        return new GroupMember(
            user.id,
            user.firstName,
            user.lastName,
            user.image,
            user.birthday,
            false,
            [],
            user.about || '',
            user.emailAddress,
            user.mobilePhoneNumber || '',
            user.phoneNumber || '',
            user.address,
            false,
            false
        )
    }

    static fromApiResponse(response: any) {
        return new GroupMember(
            response.id,
            response.firstName,
            response.lastName,
            response.image,
            response.birthday ? DateTime.fromISO(response.birthday) : undefined,
            response.hasProfile,
            response.functions,
            response.about,
            response.emailAddress,
            response.mobilePhoneNumber,
            response.phoneNumber,
            response.address,
            response.isAdmin,
            response.isApproved
        )
    }
}
