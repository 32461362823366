import { useCallback, useState } from 'react'
import { ReactComponent as AlertIcon } from 'shared/lib/assets/svg/alert.svg'
import { AlertDialog } from 'shared/lib/components/AlertDialog'
import { ErrorHandler } from 'shared/lib/components/ErrorHandler'
import { ButtonType } from 'shared/lib/components/buttons/ButtonType'
import { LocalPdf } from 'shared/lib/forms/LocalPdf'
import { Pdf } from 'shared/lib/models/Pdf'
import theme from 'shared/lib/theme/Theme'
import { eventRepository } from '../..'
import { useTranslation } from 'shared/lib/i18n'
import { EventPopup } from './EventPopup'
import { Event } from '../Event'
import { UpdateEventBody } from '../api/UpdateEventBody'
import { useCreateOrEditEventState } from './hooks'

interface Properties {
    event: Event
    onCloseButtonClicked: () => void
    onEventUpdated: (event: Event) => void
}

export const EditEventPopup = (properties: Properties) => {
    const translations = useTranslation()

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<string | undefined>(undefined)
    const [isAlertDialogVisible, setIsAlertDialogVisible] = useState(false)

    const { event, hasChanges, canSaveChanges, onFieldChanged } = useCreateOrEditEventState({
        event: properties.event,
    })

    const onPdfDeleted = useCallback(
        (pdfToDelete: Pdf | LocalPdf) => {
            if (pdfToDelete instanceof LocalPdf) {
                const updateSelectedPdfs = event.selectedPdfs.filter((pdf) => pdf !== pdfToDelete)

                onFieldChanged('selectedPdfs')(updateSelectedPdfs)
            } else {
                const updatedPdfs = event.pdfs.filter((pdf) => pdf !== pdfToDelete)

                onFieldChanged('pdfs')(updatedPdfs)
            }
        },
        [event.selectedPdfs, event.pdfs, onFieldChanged]
    )

    const onCloseButtonClicked = () => {
        if (hasChanges) {
            setIsAlertDialogVisible(true)
        } else {
            properties.onCloseButtonClicked()
        }
    }
    const onSaveButtonClicked = async () => {
        setIsLoading(true)

        try {
            const body = new UpdateEventBody(
                event.title,
                event.start,
                event.end,
                event.isAllDay,
                event.description || '',
                event.location || '',
                event.pdfs,
                event.selectedPdfs
            )
            const updatedEvent = await eventRepository.updateEvent(properties.event.id, body)
            properties.onEventUpdated(updatedEvent)
        } catch (error: any) {
            setIsLoading(false)
            setError(error.message)
        }
    }

    return (
        <>
            <EventPopup
                mode="editing"
                canSelectGroup={false}
                canContinue={hasChanges && canSaveChanges && !isLoading}
                isLoading={isLoading}
                event={event}
                onFieldChanged={onFieldChanged}
                selectedGroup={properties.event.group}
                continueButtonTitle={translations('save')}
                onCloseButtonClicked={onCloseButtonClicked}
                onSaveButtonClicked={onSaveButtonClicked}
                onPdfDeleted={onPdfDeleted}
            />

            <AlertDialog
                isLoading={false}
                isVisible={isAlertDialogVisible}
                titleIcon={<AlertIcon fill={theme.palette.primary.main} />}
                title={translations('changes_are_not_saved')}
                message={translations('are_you_sure_concept_message')}
                cancelButtonTitle={translations('cancel')}
                onCancelButtonClicked={() => setIsAlertDialogVisible(false)}
                continueButtonType={ButtonType.NONE}
                continueButtonTitle={translations('undo_changes')}
                onContinueButtonClicked={properties.onCloseButtonClicked}
            />

            <ErrorHandler error={error} translations={translations} />
        </>
    )
}
