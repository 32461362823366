import { DateTime } from 'luxon'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { VirtuosoHandle } from 'react-virtuoso'
import { useIsMobile } from 'shared/lib/theme/BreakPointHooks'
import { delay } from 'shared/lib/utils/Utils'
import { eventRepository } from '..'
import { Group, MY_CHURCH_GROUP_ID } from '../groups/Group'
import { Event as DonkeyEvent } from './Event'
import {
    EventItemViewModel,
    EventViewModel,
    GroupHeaderViewModel,
    LoadEventsErrorViewModel,
} from './EventViewModel'
import { EventViewModelBuilder } from './EventViewModelBuilder'
import { EventCreatedEvent, EventDeletedEvent, EventEventKey, EventUpdatedEvent } from './events'

interface UseEventsProperties {
    selectedGroup: Group
    homeGroupId?: string
    listRef: VirtuosoHandle | null
}

export const useEvents = ({ selectedGroup, homeGroupId, listRef }: UseEventsProperties) => {
    const isMobile = useIsMobile()

    const abortControllerRef = useRef<AbortController>()

    const [nextEventStart, setNextEventStart] = useState<string | undefined>()
    const [events, setEvents] = useState<DonkeyEvent[]>([])
    const [viewModels, setViewModels] = useState<EventViewModel[]>([])
    const [error, setError] = useState<Error | undefined>()

    const canCreateEvents = selectedGroup.canCreateEvents
    const selectedGroupId = selectedGroup.id
    const selectedGroupName = selectedGroup.name
    const isMyChurchGroup = selectedGroupId === MY_CHURCH_GROUP_ID

    const eventViewModelBuilder = useMemo(() => {
        return new EventViewModelBuilder(canCreateEvents, selectedGroupId, selectedGroupName)
    }, [canCreateEvents, selectedGroupId, selectedGroupName])

    const getEvents = useCallback(
        async (params?: { fromDate?: string; scrollToIndex?: number }) => {
            const fromDate =
                params?.fromDate ||
                DateTime.now().startOf('day').toJSDate().toISOString() ||
                undefined

            try {
                const [{ events: newEvents, nextEventStart }] = await Promise.all([
                    eventRepository.getEvents(
                        {
                            groupId: isMyChurchGroup ? undefined : selectedGroupId,
                            from: fromDate,
                        },
                        abortControllerRef.current?.signal
                    ),
                    delay(params?.fromDate ? 0 : 300),
                ])
                setNextEventStart(nextEventStart)
                setEvents((prevState) => {
                    const allEvents = params?.fromDate ? [...prevState, ...newEvents] : newEvents

                    const viewModels = eventViewModelBuilder.build({
                        events: allEvents,
                        nextEventStart,
                    })

                    if (
                        !isMobile &&
                        selectedGroupId &&
                        ![homeGroupId, MY_CHURCH_GROUP_ID].includes(selectedGroupId)
                    ) {
                        viewModels.unshift(
                            new GroupHeaderViewModel({
                                id: selectedGroupId,
                                name: selectedGroupName,
                            })
                        )
                    }

                    setViewModels(viewModels)

                    return allEvents
                })

                if (listRef && params?.scrollToIndex) {
                    listRef.scrollToIndex({
                        index: params?.scrollToIndex,
                        behavior: 'auto',
                        align: 'center',
                    })
                }
            } catch (error: any) {
                if (error.name === 'CanceledError') {
                    return
                }

                setError(error)
                setViewModels([new LoadEventsErrorViewModel()])
                return
            }
        },
        [
            isMyChurchGroup,
            isMobile,
            homeGroupId,
            selectedGroupId,
            selectedGroupName,
            eventViewModelBuilder,
            listRef,
        ]
    )

    const loadMoreEventsIfNeeded = () => {
        const hasFetchedAll = nextEventStart === undefined

        if (hasFetchedAll) {
            return
        }

        getEvents({ fromDate: nextEventStart })
    }

    useEffect(() => {
        const onEventCreated = (event: Event) => {
            const createdEvent = (event as EventCreatedEvent).event

            if (createdEvent.group.id !== selectedGroupId) return

            listRef?.scrollToIndex({ index: 0, behavior: 'auto' })
            setEvents([])
            setViewModels([])
            getEvents()
        }

        const onEventUpdated = (event: Event) => {
            const updatedEvent = (event as EventUpdatedEvent).event
            const index = viewModels.findIndex(
                (viewModel) =>
                    viewModel instanceof EventItemViewModel &&
                    viewModel.event.id === updatedEvent.id
            )

            setEvents([])
            setViewModels([])
            getEvents({ scrollToIndex: index })
        }

        const onEventDeleted = (event: Event) => {
            const deletedEventId = (event as EventDeletedEvent).eventId
            const updatedEvents = events.filter((event) => event.id !== deletedEventId)
            const updatedViewModels = eventViewModelBuilder.build({
                events: updatedEvents,
                nextEventStart,
            })

            setEvents(updatedEvents)
            setViewModels(updatedViewModels)
        }

        document.addEventListener(EventEventKey.EVENT_CREATED, onEventCreated)
        document.addEventListener(EventEventKey.EVENT_UPDATED, onEventUpdated)
        document.addEventListener(EventEventKey.EVENT_DELETED, onEventDeleted)

        return () => {
            document.removeEventListener(EventEventKey.EVENT_CREATED, onEventCreated)
            document.removeEventListener(EventEventKey.EVENT_UPDATED, onEventUpdated)
            document.removeEventListener(EventEventKey.EVENT_DELETED, onEventDeleted)
        }
    }, [
        selectedGroupId,
        viewModels,
        events,
        eventViewModelBuilder,
        nextEventStart,
        listRef,
        getEvents,
    ])

    useEffect(() => {
        if (!homeGroupId) {
            return
        }

        abortControllerRef.current?.abort()
        abortControllerRef.current = new AbortController()

        listRef?.scrollToIndex({ index: 0, behavior: 'auto' })

        setEvents([])
        setViewModels([])
        getEvents()

        return () => {
            abortControllerRef.current?.abort()
        }
    }, [selectedGroupId, homeGroupId, listRef, getEvents])

    return {
        viewModels,
        error,
        loadMoreEventsIfNeeded,
    }
}
