import { Typography } from '@mui/material'
import i18n from '../../../i18n'
import { Event } from '../../Event'

export const StartDateSingleDay = ({ event }: { event: Event }) => {
    if (!event.isSameDay) {
        return null
    }

    return (
        <Typography>
            {event.start.toLocaleString(
                {
                    weekday: 'long',
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                },
                {
                    locale: i18n.language,
                }
            )}
        </Typography>
    )
}
