import { Event } from './Event'

export enum EventEventKey {
    EVENT_CREATED = 'event_created',
    EVENT_UPDATED = 'event_updated',
    EVENT_DELETED = 'event_deleted',
}

export class EventCreatedEvent extends CustomEvent<{
    event: Event
}> {
    constructor(public readonly event: Event) {
        super(EventEventKey.EVENT_CREATED, {
            detail: {
                event,
            },
        })
    }
}

export class EventUpdatedEvent extends CustomEvent<{
    event: Event
}> {
    constructor(public readonly event: Event) {
        super(EventEventKey.EVENT_UPDATED, {
            detail: {
                event,
            },
        })
    }
}

export class EventDeletedEvent extends CustomEvent<{
    eventId: string
}> {
    constructor(public readonly eventId: string) {
        super(EventEventKey.EVENT_DELETED, {
            detail: {
                eventId,
            },
        })
    }
}
