import { Box } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import i18next from 'i18next'
import { DateTime } from 'luxon'
import { memo, useState } from 'react'
import { DatePicker } from 'shared/lib/components/textfields/DatePicker'
import { TimePicker } from 'shared/lib/components/textfields/TimePicker'
import { DateTimeValidator } from 'shared/lib/utils/DateTimeValidator'
import { SwitchRow } from '../../components/switch/SwitchRow'
import { useTranslation } from 'shared/lib/i18n'

interface Properties {
    allowStartInPast: boolean
    startDate: DateTime
    endDate: DateTime
    isAllDay: boolean
    isLoading: boolean

    onStartDateChanged(value: DateTime): void
    onEndDateChanged(value: DateTime): void
    onIsAllDayChanged(value: boolean): void
}

const Component = ({
    startDate,
    endDate,
    isAllDay,
    allowStartInPast,
    ...properties
}: Properties) => {
    const translations = useTranslation()

    const [startDateErrorMessage, setStartDateErrorMessage] = useState<string | undefined>()
    const [endDateErrorMessage, setEndDateErrorMessage] = useState<string | undefined>()

    const onIsAllDayChanged = (value: boolean) => {
        properties.onIsAllDayChanged(value)
    }

    const onStartDateChanged = (value: DateTime) => {
        if (value > endDate) {
            const end = value.plus({ hours: 1 })
            validatePeriod(value, end, isAllDay)
            properties.onStartDateChanged(value)
            properties.onEndDateChanged(end)
            return
        }

        validatePeriod(startDate, endDate, isAllDay)
        properties.onStartDateChanged(value)
    }

    const onEndDateChanged = (value: DateTime) => {
        if (value < startDate) {
            const start = value.minus({ hours: 1 })
            validatePeriod(start, value, isAllDay)
            properties.onStartDateChanged(start)
            properties.onEndDateChanged(value)
            return
        }

        properties.onEndDateChanged(value)
    }

    const validatePeriod = (startDate: DateTime, endDate: DateTime, isAllDay: boolean) => {
        const startDateErrorMessageKey = DateTimeValidator.startDateErrorKey(
            startDate,
            endDate,
            isAllDay,
            { allowStartInPast }
        )
        const endDateErrorMessageKey = DateTimeValidator.endDateErrorKey(
            endDate,
            startDate,
            isAllDay
        )
        setStartDateErrorMessage(
            startDateErrorMessageKey ? translations(startDateErrorMessageKey) : undefined
        )
        setEndDateErrorMessage(
            endDateErrorMessageKey ? translations(endDateErrorMessageKey) : undefined
        )
    }

    return (
        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={i18next.language}>
            <Box display={'flex'} flexDirection={'column'}>
                <Box pl={1}>
                    <SwitchRow
                        title={translations('agenda_all_day')}
                        isChecked={isAllDay}
                        onChanged={onIsAllDayChanged}
                        isDisabled={properties.isLoading}
                    />
                </Box>

                <Box display="flex" flexDirection="row" mt={2}>
                    <Box flexGrow={1}>
                        <DatePicker
                            disablePast={!allowStartInPast}
                            label={translations('start_date')}
                            value={startDate}
                            maxDate={DateTimeValidator.MAX_DATE}
                            helperText={startDateErrorMessage}
                            onChange={(value) => {
                                if (value) onStartDateChanged(value)
                            }}
                            disabled={properties.isLoading}
                        />
                    </Box>
                    {!isAllDay && (
                        <Box ml={3}>
                            <TimePicker
                                ampm={false}
                                label={translations('start_time')}
                                value={startDate}
                                onChange={(value) => {
                                    if (value) onStartDateChanged(value)
                                }}
                                disabled={properties.isLoading}
                            />
                        </Box>
                    )}
                </Box>

                <Box display="flex" flexDirection="row" mt={2}>
                    <Box flexGrow={1}>
                        <DatePicker
                            disablePast={true}
                            label={translations('end_date')}
                            value={endDate}
                            maxDate={DateTimeValidator.MAX_DATE}
                            helperText={endDateErrorMessage}
                            onChange={(value) => {
                                if (value) onEndDateChanged(value)
                            }}
                            disabled={properties.isLoading}
                        />
                    </Box>
                    {!isAllDay && (
                        <Box ml={3}>
                            <TimePicker
                                ampm={false}
                                label={translations('end_time')}
                                value={endDate}
                                onChange={(value) => {
                                    if (value) onEndDateChanged(value)
                                }}
                                disabled={properties.isLoading}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
        </LocalizationProvider>
    )
}

export const EventDurationView = memo(Component, (previous, next) => {
    return (
        previous.startDate === next.startDate &&
        previous.endDate === next.endDate &&
        previous.isAllDay === next.isAllDay &&
        previous.isLoading === next.isLoading
    )
})
