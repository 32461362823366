import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { notificationRepository, sessionRepository } from '..'
import ChurchContext from '../churches/ChurchContextProvider'
import { Paths } from '../routing/paths'
import { UpdateUserParams } from '../user/models/UpdateUserParams'
import { ConfirmChangePasswordParams } from './models/ConfirmChangePasswordParams'
import { ConfirmSignInParams } from './models/ConfirmSignInParams'
import { SignedInUser } from './models/SignedInUser'
import { SignInParams } from './models/SignInParams'
import { OnSignedOutObserver } from './SessionRepository'
import { ErrorCode, getErrorCode } from 'shared/lib/utils/ErrorUtils'

const SessionContext = createContext<
    | {
          isRefreshing: boolean

          getIsSignedIn(): boolean
          getSignedInUser(): SignedInUser | undefined
          signIn(params: SignInParams): Promise<void>
          confirmSignIn(params: ConfirmSignInParams): Promise<void>
          resendSMSMFACode(params: SignInParams): Promise<{ mfaSession: string }>
          confirmChangePassword(params: ConfirmChangePasswordParams): Promise<void>
          updateSignedInUser(params: UpdateUserParams): Promise<void>
          signOut(redirectLocation?: Location): void
      }
    | undefined
>(undefined)

export const SessionContextProvider = ({ children }: PropsWithChildren) => {
    const location = useLocation()
    const navigate = useNavigate()

    const { applicationId, getChurch } = useContext(ChurchContext)!

    const [isRefreshing, setIsRefreshing] = useState(true)

    const isSignedIn = sessionRepository.isSignedIn

    const nonAuthorizedPaths = [
        Paths.SIGN_IN,
        Paths.CHANGE_PASSWORD,
        Paths.FORGOT_PASSWORD,
        Paths.FORGOT_PASSWORD_SEND,
        Paths.SIGN_UP,
        Paths.SELECT_CHURCH,
        Paths.SALVATION_ARMY,
    ]

    const isNonAuthorizedPath = (pathname: string) => {
        const path = pathname.split('?')[0]
        return nonAuthorizedPaths.includes(path as Paths)
    }

    const onSignedOut: OnSignedOutObserver = (
        applicationId: string,
        isIntentionalSignOut: boolean
    ) => {
        notificationRepository.clearData()
        navigateToSignInPage(applicationId, !isIntentionalSignOut)
    }

    const signIn = async (params: SignInParams): Promise<void> => {
        const response = await sessionRepository.signIn(params)

        if (response.mfaSession) {
            navigate(
                {
                    pathname: Paths.CONFIRM_SIGN_IN,
                },
                {
                    state: {
                        emailAddress: params.emailAddress,
                        password: params.password,
                        mfaSession: response.mfaSession,
                        mfaMethod: response.mfaMethod,
                        mfaPhoneNumber: response.mfaPhoneNumber,
                        useSessionStorage: params.useSessionStorage,
                    },
                }
            )
            return
        }

        onSuccessFullySignedIn()
    }

    const confirmSignIn = async (params: ConfirmSignInParams): Promise<void> => {
        try {
            await sessionRepository.confirmSign(params)
            onSuccessFullySignedIn()
        } catch (error) {
            if (getErrorCode(error) === ErrorCode.INVALID_MFA_SESSION) {
                navigate(Paths.SIGN_IN)
                return
            }

            throw error
        }
    }

    const onSuccessFullySignedIn = () => {
        const redirectLocation = location.state?.redirectLocation

        const path =
            !redirectLocation || isNonAuthorizedPath(redirectLocation)
                ? Paths.TIME_LINE
                : redirectLocation

        navigate(path, { replace: true })
    }

    const resendSMSMFACode = async (params: SignInParams) => {
        const response = await sessionRepository.signIn(params)
        return { mfaSession: response.mfaSession! }
    }

    const confirmChangePassword = async (params: ConfirmChangePasswordParams): Promise<void> => {
        await sessionRepository.confirmChangePassword(params)

        await signIn({
            emailAddress: params.emailAddress,
            password: params.password,
            useSessionStorage: true,
        })
    }

    const updateSignedInUser = async (params: UpdateUserParams): Promise<void> => {
        await sessionRepository.updateSignedInUser(params)
    }

    const signOut = () => {
        sessionRepository.signOut()
    }

    const navigateToSignInPage = (applicationId?: string, useRedirectLocation: boolean = true) => {
        const url = applicationId
            ? `${Paths.SIGN_IN}?applicationId=${applicationId}`
            : location.pathname === Paths.SALVATION_ARMY
              ? Paths.SALVATION_ARMY
              : Paths.SELECT_CHURCH

        const redirectLocation = useRedirectLocation
            ? location.pathname + location.search
            : undefined
        navigate(url, {
            replace: true,
            state: { redirectLocation },
        })
    }

    useEffect(() => {
        sessionRepository.addObserver(onSignedOut)

        const isAuthorizedPath = !isNonAuthorizedPath(location.pathname)

        if (isSignedIn && !isAuthorizedPath) {
            navigate(Paths.TIME_LINE, { replace: true })
        } else if (!isSignedIn && isAuthorizedPath) {
            sessionRepository.clearData()
            navigateToSignInPage(applicationId)
        }

        if (isSignedIn) {
            sessionRepository.refreshSignedInUser().finally(() => setIsRefreshing(false))
        }

        return () => {
            sessionRepository.removeObserver(onSignedOut)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getChurch()
    }, [isSignedIn])

    return (
        <SessionContext.Provider
            value={{
                isRefreshing,
                getIsSignedIn: () => sessionRepository.isSignedIn,
                getSignedInUser: () => sessionRepository.signedInUser,
                signIn,
                confirmSignIn,
                resendSMSMFACode,
                confirmChangePassword,
                updateSignedInUser,
                signOut,
            }}
        >
            {children}
        </SessionContext.Provider>
    )
}

export default SessionContext
