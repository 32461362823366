import { SVGProps } from './SVGProps'

export const ChevronDownIcon = (properties: SVGProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={properties.color || 'current'}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.21967 8.21967C3.51256 7.92678 3.98744 7.92678 4.28033 8.21967L11.75 15.6893L19.2197 8.21967C19.5126 7.92678 19.9874 7.92678 20.2803 8.21967C20.5732 8.51256 20.5732 8.98744 20.2803 9.28033L12.2803 17.2803C11.9874 17.5732 11.5126 17.5732 11.2197 17.2803L3.21967 9.28033C2.92678 8.98744 2.92678 8.51256 3.21967 8.21967Z"
            fill="current"
        />
    </svg>
)
