import { styled, Switch, SwitchProps } from '@mui/material'
import { grey_1, grey_3 } from 'shared/lib/theme/Theme'

interface Props extends SwitchProps {}

export const IOSSwitch = styled((props: Props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 58,
    height: 32,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(26px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#59cd90',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                backgroundColor: '#59cd90',
                opacity: 0.4,
            },
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: 'white',
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: grey_3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 28,
        height: 28,
    },
    '& .MuiSwitch-track': {
        borderRadius: 32 / 2,
        backgroundColor: grey_1,
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}))
