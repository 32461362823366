import { Box, BoxProps, styled, Typography, TypographyProps } from '@mui/material'
import { forwardRef, memo, Ref, SVGProps, useRef, useState } from 'react'
import { ReactComponent as DownChevron } from 'shared/lib/assets/svg/chevron_down.svg'
import { grey_1, grey_2 } from 'shared/lib/theme/Theme'
import { useTranslation } from 'shared/lib/i18n'
import { fileRepository } from '../../index'
import { Group, MinimalGroupInfo } from '../Group'
import { GroupAvatar } from '../GroupAvatar'
import { SelectGroupPopup } from './SelectGroupPopup'

interface Properties {
    groups?: Group[]
    isEnabled: boolean
    selectedGroup?: MinimalGroupInfo
    onGroupSelected?: (group: Group) => void
}

const Component = forwardRef(
    ({ selectedGroup, groups = [], ...properties }: Properties, ref: Ref<HTMLDivElement>) => {
        const translations = useTranslation()

        const anchorRef = useRef<HTMLDivElement | null>(null)
        const [isPopupVisible, setIsPopupVisible] = useState(false)

        const canCreatePostGroups = groups.filter(
            (group) => group.canCreatePosts && !group.isMyChurch
        )

        const onGroupSelected = (group: Group) => {
            properties.onGroupSelected?.(group)
            setIsPopupVisible(false)
        }

        return (
            <>
                <Container
                    ref={ref}
                    isEnabled={properties.isEnabled}
                    onClick={() => properties.isEnabled && setIsPopupVisible(true)}
                >
                    <GroupAvatar
                        ref={anchorRef}
                        src={fileRepository.getThumbnailUrl(selectedGroup?.image)}
                    />

                    <TextContainer>
                        <PostInTextView variant="body2">{translations('post_in')}</PostInTextView>

                        <GroupNameContainer>
                            <GroupNameTextView isEnabled={properties.isEnabled} variant="body1">
                                {selectedGroup
                                    ? selectedGroup.name
                                    : translations('select_a_group')}
                            </GroupNameTextView>

                            <Chevron isEnabled={properties.isEnabled} />
                        </GroupNameContainer>
                    </TextContainer>
                </Container>

                <SelectGroupPopup
                    isVisible={isPopupVisible && properties.isEnabled}
                    anchor={anchorRef.current}
                    groups={canCreatePostGroups}
                    selectedGroup={selectedGroup}
                    onDismissed={() => setIsPopupVisible(false)}
                    onGroupSelected={onGroupSelected}
                />
            </>
        )
    }
)

export const GroupSelector = memo(Component, (previous, next) => {
    return (
        previous.isEnabled === next.isEnabled &&
        previous.groups?.length === next.groups?.length &&
        previous.selectedGroup?.id === next.selectedGroup?.id
    )
})

const Container = styled(
    forwardRef(
        ({ isEnabled, ...props }: BoxProps & { isEnabled: boolean }, ref: Ref<HTMLDivElement>) => (
            <Box ref={ref} {...props} />
        )
    )
)(({ theme, isEnabled }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    cursor: isEnabled ? 'pointer' : 'default',
    overflow: 'hidden',
    alignItems: 'center',
    paddingRight: theme.spacing(2),
}))

const TextContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    paddingLeft: theme.spacing(1),
}))

const PostInTextView = styled(Typography)(() => ({
    fontSize: '12px',
    fontWeight: 400,
    color: grey_1,
}))

const GroupNameContainer = styled(Box)(() => ({
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
}))

const GroupNameTextView = styled(
    ({ isEnabled, ...props }: TypographyProps & { isEnabled: boolean }) => <Typography {...props} />
)(({ theme, isEnabled }) => ({
    display: 'inline-block',
    maxWidth: '80%',
    fontSize: '12px',
    fontWeight: 700,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: isEnabled ? theme.palette.primary.main : grey_2,
}))

const Chevron = styled(
    ({ ref, isEnabled, ...props }: SVGProps<SVGElement> & { isEnabled: boolean }) => (
        <DownChevron {...props} />
    )
)(({ theme, isEnabled }) => ({
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    fill: isEnabled ? theme.palette.primary.main : grey_2,
}))
