import { styled } from '@mui/material'
import MuiGrid from '@mui/material/Unstable_Grid2'
import { useState } from 'react'
import { useIsMobile, useIsPhablet } from 'shared/lib/theme/BreakPointHooks'
import {
    defaultTransition,
    mobileBottomBarHeight,
    mobileToolbarHeight,
} from 'shared/lib/theme/Theme'
import { churchRepository } from '../..'
import { DetailView } from '../../common/detailView/DetailView'
import { useDetailView } from '../../common/detailView/hooks'
import { ContentContainer } from '../../common/layout/ContentContainer'
import { MobileHeader } from '../../common/layout/MobileHeader'
import { useTranslation } from 'shared/lib/i18n'
import { filterUsers } from '../Utils'
import { useUsers } from '../hooks'
import { MobileUserListView } from './mobile/MobileUserListView'
import { TabletDesktopUserList } from './tabletDesktop/TabletDesktopUserList'

export const UserListPage = () => {
    const translations = useTranslation()

    const { users, isLoading } = useUsers({ isApproved: true })
    const { isDetailViewOpen, isDetailViewDisplayedAsPopup, openUserDetailView } = useDetailView()

    const [isMobile, isPhablet] = [useIsMobile(), useIsPhablet()]

    const [query, setQuery] = useState('')

    const church = churchRepository.church
    const filteredUsers = filterUsers(users, query)
    const hasNoResults = Boolean(query.length && !filteredUsers.length)

    if (!church) {
        return null
    }

    const pageTitle = translations(church.getUserListPageNameTranslationKey())

    const onUserSelected = (userId: string) => openUserDetailView(userId, { resetHistory: true })

    if (isMobile) {
        return (
            <>
                <MobileHeader
                    hasBackButton={false}
                    hasSubTitle={true}
                    canShowSmartBanner={true}
                    title={pageTitle}
                    subTitle={
                        isLoading
                            ? ''
                            : translations('plural_members', [users.length], users.length)
                    }
                />
                <ContentContainer mt={mobileToolbarHeight} mb={mobileBottomBarHeight}>
                    <MobileUserListView
                        users={filteredUsers}
                        query={query}
                        hasNoResults={hasNoResults}
                        isLoading={isLoading}
                        onUserSelected={onUserSelected}
                        setQuery={setQuery}
                    />
                </ContentContainer>

                {isDetailViewOpen && <DetailView />}
            </>
        )
    }

    return (
        <>
            {isPhablet && (
                <MobileHeader
                    hasBackButton={false}
                    hasSubTitle={true}
                    title={pageTitle}
                    subTitle={
                        isLoading
                            ? ''
                            : translations('plural_members', [users.length], users.length)
                    }
                />
            )}
            <ContentContainer
                mt={isPhablet ? mobileToolbarHeight : undefined}
                mb={isPhablet ? mobileBottomBarHeight : undefined}
            >
                <Grid container columns={{ mobile: 4, phablet: 4, tablet: 8, desktop: 12 }}>
                    <Grid
                        phablet={isDetailViewOpen && !isDetailViewDisplayedAsPopup ? 2 : 4}
                        tablet={isDetailViewOpen && !isDetailViewDisplayedAsPopup ? 5 : 8}
                        desktop={isDetailViewOpen && !isDetailViewDisplayedAsPopup ? 8 : 10}
                        desktopOffset={isDetailViewOpen && !isDetailViewDisplayedAsPopup ? 0 : 1}
                    >
                        <TabletDesktopUserList
                            title={pageTitle}
                            users={filteredUsers}
                            totalUserCount={users.length}
                            query={query}
                            hasNoResults={hasNoResults}
                            isLoading={isLoading}
                            onUserSelected={onUserSelected}
                            setQuery={setQuery}
                        />
                    </Grid>

                    {isDetailViewOpen && (
                        <Grid phablet={2} tablet={3} desktop={4}>
                            <DetailView />
                        </Grid>
                    )}
                </Grid>
            </ContentContainer>
        </>
    )
}

const Grid = styled(MuiGrid)(() => ({
    height: '100%',
    transition: defaultTransition,
}))
