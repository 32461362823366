import { Box } from '@mui/material'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { ReactComponent as AlertIcon } from 'shared/lib/assets/svg/alert.svg'
import { AlertDialog } from 'shared/lib/components/AlertDialog'
import { ErrorHandler } from 'shared/lib/components/ErrorHandler'
import { ButtonType } from 'shared/lib/components/buttons/ButtonType'
import { errorColor } from 'shared/lib/theme/Theme'
import { DetailViewContainer } from '../common/detailView'
import { DETAIL_VIEW_HEADER_BOTTOM_Y } from '../common/detailView/DetailViewHeader'
import { DetailViewProperties } from '../common/detailView/DetailViewProperties'
import { useTranslation } from 'shared/lib/i18n'
import { sessionRepository } from '../index'
import { Post } from '../posts/Post'
import { PostPopularLikesView } from '../posts/components/PostPopularLikesView'
import { usePost } from '../posts/hooks'
import { CommentListItemViewModel, CommentListLoadingViewModel } from './CommentListViewModels'
import { CommentsList } from './CommentsList'
import { CreateOrEditCommentView } from './CreateOrEditCommentView'
import { NoCommentsView } from './NoCommentsView'
import { useComments } from './hooks'
import { useDetailView } from '../common/detailView/hooks'

interface Properties extends DetailViewProperties {
    postId: string
}

export const CommentsListView = ({ postId, closeIcon, onCloseClicked }: Properties) => {
    const translations = useTranslation()
    const location = useLocation()
    const postFromState = location.state?.post ? Post.fromJSON(location.state.post) : undefined

    const signedInUser = sessionRepository.signedInUser

    const { isDetailViewDisplayedAsPopup: isDetailViewOpenAsPopup } = useDetailView()
    const { error: postError, post, getPost } = usePost(postFromState)
    const {
        isLoading,
        isUpdatingOrDeleting,
        comments,
        commentToEdit,
        commentIdToDelete,
        indexToScrollTo,
        error,
        getComments,
        setCommentToEdit,
        setCommentToDelete,
        deleteComment,
        setIndexToScrollTo,
    } = useComments(postId)

    const numberOfComments = (comments.length || post?.numberOfComments) ?? 0

    const commentsViewModels = isLoading
        ? Array.from({ length: 10 }, () => new CommentListLoadingViewModel())
        : comments.map(
              (comment) => new CommentListItemViewModel(comment, comment.id === commentToEdit?.id)
          )
    const isUserCreatorFromSelectedComment = commentToEdit?.creator?.id === signedInUser?.id

    const onContinueDeleteButtonClicked = () => {
        if (!commentIdToDelete) {
            return
        }

        deleteComment(commentIdToDelete)
    }

    useEffect(() => {
        getComments()
    }, [postId, getComments])

    useEffect(() => {
        getPost(postId)
    }, [postId, getPost])

    return (
        <>
            <DetailViewContainer
                onCloseClicked={onCloseClicked}
                closeIcon={closeIcon}
                title={translations('plural_comments', [numberOfComments], numberOfComments)}
                contentMarginTop={DETAIL_VIEW_HEADER_BOTTOM_Y}
            >
                {post && (
                    <Box mx={3}>
                        <PostPopularLikesView post={post} />
                    </Box>
                )}

                <Box height="100%" id="comments-list-view">
                    {isLoading || comments.length ? (
                        <CommentsList
                            comments={commentsViewModels}
                            indexToScrollTo={indexToScrollTo}
                            onScrollEnded={() => setIndexToScrollTo(undefined)}
                            onEditButtonClicked={(comment) => setCommentToEdit(comment)}
                            onDeleteButtonClicked={(commentId) => setCommentToDelete(commentId)}
                        />
                    ) : (
                        <NoCommentsView />
                    )}
                </Box>

                <CreateOrEditCommentView
                    postId={postId}
                    groupId={post?.groupId}
                    isUserCreatorFromSelectedComment={isUserCreatorFromSelectedComment}
                    commentToEdit={commentToEdit}
                    onScrollToCommentButtonClicked={() =>
                        commentToEdit && setIndexToScrollTo(comments.indexOf(commentToEdit))
                    }
                    onCloseButtonClicked={() => setCommentToEdit(undefined)}
                />
            </DetailViewContainer>

            <AlertDialog
                isLoading={isUpdatingOrDeleting}
                isVisible={!!commentIdToDelete}
                titleIcon={<AlertIcon fill={errorColor} />}
                title={translations('remove_comment_message')}
                cancelButtonTitle={translations('cancel')}
                onCancelButtonClicked={() => setCommentToDelete(undefined)}
                continueButtonType={ButtonType.RED}
                continueButtonTitle={translations('remove_comment')}
                onContinueButtonClicked={onContinueDeleteButtonClicked}
            />

            <ErrorHandler
                error={error || postError}
                translations={translations}
                horizontal={isDetailViewOpenAsPopup ? 'center' : 'right'}
            />
        </>
    )
}
